import React, { useEffect, useState } from 'react';
import { ReactComponent as NoDocuments } from '../.././utils/SVGs/NoDocuments.svg';
import { ReactComponent as Cancel } from '../.././utils/SVGs/Cancel.svg';
import { ReactComponent as TickIcon } from '../.././utils/SVGs/tick_icon_stroke.svg';
import { postRequest } from '../../../../../utils/handler/apiHandler';
import { useLocation } from "react-router-dom";
import { Button, Col, Row, Spin, message } from 'antd';
import DocumentItem from './DocumentItem';
import UploadDocuments from './UploadDocuments';
import ViewDocuments from './ViewDocuments';
import RemarkAndFeedbackEditor from '../../../../../utils/helpers/RemarkAndFeedbackEditor';
import CustomConfirmationModal from '../../../../../utils/CustomConfirmationModal';
import CustomConfirmationModalButtomColorReversed from '../../../../../utils/CustomConfirmationModalButtomColorReversed';
import { getDisableModifyButtonFlag } from './documentUtils';
import { CREDIT_EDITABLE_DOCUMENT_STATE } from '../../../constants';

interface DocumentBodyProps {
    allowedActionsForProspect: Set<string>;
    documents: any[];
    loader: boolean;
    showCancelRequest: boolean;
    userRole: string;
    showCompleteReview: boolean;
    showSubmitFeedback: boolean;
    docUploadStatus: any;
    reviewForRequestAllowed: boolean;
    stageId: number;
    setReloadDocumentTab: (reload: number) => void;
}
    
const DocumentBody: React.FC<DocumentBodyProps> = ({  allowedActionsForProspect, stageId, documents, loader, showCancelRequest, userRole,
     showCompleteReview, showSubmitFeedback, docUploadStatus, reviewForRequestAllowed, setReloadDocumentTab }) => {
    const [showUpload, setShowUpload] = useState<boolean> (false);
    const [showViewDocs, setShowViewDocs] = useState<boolean> (false);
    const [documentActionTargetId, setDocumentActionTargetId] = useState<number> (-1);
    const [isTextEditorModalVisible, setIsTextEditorModalVisible] = useState(false);
    const [isFeedbackTextEditorModalVisible, setIsFeedbackTextEditorModalVisible] = useState(false);
    const [editable, setEditable] = useState(false);
    const [feedbackEditable, setFeedbackEditable] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showConfirmRequest, setShowConfirmRequest] = useState(false);
    const [showRejectRequestModal, setShowRejectRequestModal] = useState(false);
    const [showConfirmFeedbackRequestModal, setShowConfirmFeedbackRequestModal] = useState(false);
    const [showConfirmCompleteRequestModal, setShowConfirmCompleteRequestModal] = useState(false);
    const [showCancelRequestModal, setShowCancelRequestModal] = useState(false);
    const [viewerTitle, setViewerTitle] = useState("");
    const [noteText, setNoteText] = useState("");
    const [viewerFeedbackTitle, setViewerFeedbackTitle] = useState("");
    const [isRejectFeedback, setIsRejectFeedback] = useState<boolean>(false);
    const [noteFeedbackText, setNoteFeedbackText] = useState("");
    const [prospectDocId, setProspectDocId] = useState<number>();
    
    const url = useLocation();
    const prospectId = url.pathname.split("/").pop();
    
    const onEditorClose = () => {
        setIsTextEditorModalVisible(false);
        setEditable(false);
    }
    const onFeedbackEditorClose = () => {
        setIsFeedbackTextEditorModalVisible(false);
        setFeedbackEditable(false);
    }
    const updateNote = async (): Promise<void> => {
        try{
          setLoading(true);
          const payload = {
            "prospectDocId": documentActionTargetId,
            "comment": noteText,
            "isRemark":true 
          }
          await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/prospectDoc/addCommentNew`,
          payload);
        }catch( error) {
          console.error('Error in updating remarks note:', error);
        }finally{
          setLoading(false);
        }
        setReloadDocumentTab(Math.random());
        
    };
    const updateFeedbackNote = async (): Promise<void> => {
        try{
          setLoading(true);
          const payload = {
            "prospectDocId": documentActionTargetId,
            "comment": noteFeedbackText,
            "isRemark":false 
          }
          await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/prospectDoc/addCommentNew`,
          payload);
        }catch( error) {
          console.error('Error in updating feedback note:', error);
        }finally{
          setLoading(false);
        }
        if(isRejectFeedback){
          handleRejectDocumentAfterFeedback();
          setIsRejectFeedback(false);
        }
        else{
          setReloadDocumentTab(Math.random());
        }
        
    };
    
    function formatDateTime(dateTimeString: string): string {
        const date = new Date(dateTimeString);
        const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: '2-digit', month: 'short', day: 'numeric', year: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }
    const editProspectNote = (record : any) => {
        setViewerTitle(record?.remarksUpdatedAt ? formatDateTime(record?.remarksUpdatedAt):'')
        setIsTextEditorModalVisible(true);
        if(record?.remark){
            setNoteText(record?.remark);
        }
        else{
            setNoteText("");
            if(allowedActionsForProspect?.has('addRemarks')){
              setEditable(true);
            }
        }
    }
    const editFeedbackNote = (record : any) => {
      setViewerFeedbackTitle((record?.feedback) ? formatDateTime( record?.feedbackUpdatedAt ): '')
        setIsFeedbackTextEditorModalVisible(true);
        if(record?.feedback){
            setNoteFeedbackText(record?.feedback);
        }
        else{
            setNoteFeedbackText("");
            if(allowedActionsForProspect?.has('feedback')){
              setFeedbackEditable(true);
            }
        }
    }

    const onSave = () => {
        updateNote();
        setIsTextEditorModalVisible(false);
        setEditable(false);
    }
    const onFeedbackSave = () => {
        updateFeedbackNote();
        setIsFeedbackTextEditorModalVisible(false);
        setFeedbackEditable(false);
       
    }
    const handleReviewRequest = async ()=> {
        try{
            setLoading(true);
            await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/prospectDoc/raiseRequestNew?prospectId=${prospectId}`,
            {});
          }catch( error) {
            console.error('Error in raising review request:', error);
          }finally{
            setLoading(false);
          }
          message.success('Review request has been raised');
          setTimeout(()=>{
            setReloadDocumentTab(Math.random());
          },1000)         
          
    }    

    const handleSubmitFeedback = async() => {
      try{
          setLoading(true);
          await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/prospectDoc/submitReviewFeedbackNew?prospectId=${prospectId}`,
          {});
        }catch( error) {
          console.error('Error in submiting feedback:', error);
        }finally{
          setLoading(false);
        }
        message.success('Feedback Submitted');
        setTimeout(()=>{
          setReloadDocumentTab(Math.random());
        },1000) 
    }

    const handleCancelRequest = async ()=>{
        try{
            setLoading(true);
            await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/prospectDoc/cancelRequestNew?prospectId=${prospectId}`,
            {});
          }catch( error) {
            console.error('Error in canceling review request:', error);
          }finally{
            setLoading(false);
          }
          message.success('Review request has been cancelled');
          setTimeout(()=>{
            setReloadDocumentTab(Math.random());
          },1000)         
          
    }
    
    const handleConfirmRequestCancel = () => {
        setShowConfirmRequest(false);
    }
    const handleConfirmRequestSave = () => {
        setShowConfirmRequest(false);
        handleReviewRequest();
    };
    const handleRejectRequestCancel = () => {
      setShowRejectRequestModal(false);
      setIsRejectFeedback(false);
  }
  const handleRejectRequestSave = () => {
      setShowRejectRequestModal(false);
      setIsFeedbackTextEditorModalVisible(true);
  };
    const handleSubmitFeedbackCancel = () => {
      setShowConfirmFeedbackRequestModal(false);
    }
    const handleSubmitFeedbackSave = () => {
      setShowConfirmFeedbackRequestModal(false);
      handleSubmitFeedback();
    };
    const handleCompleteRequestCancel = () => {
      setShowConfirmCompleteRequestModal(false);
    }
    const handleCompleteRequestSave = () => {
      setShowConfirmCompleteRequestModal(false);
      handleCompleteReview();
    };
    const handleCancelRequestCancel = () => {
        setShowCancelRequestModal(false);
    }
    const handleCancelRequestSave = () => {
        setShowCancelRequestModal(false);
        handleCancelRequest();
    };
    
    const handleRejectDocument = async (record: any) => {
        setShowRejectRequestModal(true);
        const prospectDocId = record?.prospectDocId;
        setViewerFeedbackTitle((record?.feedback) ? formatDateTime( record?.feedbackUpdatedAt ): '')
        setIsRejectFeedback(true);
        setProspectDocId(prospectDocId);
        setDocumentActionTargetId(prospectDocId)
        if(record?.feedback){
            setNoteFeedbackText(record?.feedback);
        }
        else{
            setNoteFeedbackText("");
            setFeedbackEditable(true);
        }
        
    }
    const handleRejectDocumentAfterFeedback = async () =>{
      try{
        setLoading(true);
        const payload = {
          "prospectDocId": prospectDocId,
          "approvalStatus": "Reject"
        }
        await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/prospectDoc/approveRejectNew`,
        payload);
      }catch( error) {
        console.error('Error in rejecting document:', error);
      }finally{
        setLoading(false);
      }
      message.success('Document has been rejected');
      setTimeout(()=>{
        setReloadDocumentTab(Math.random());
      },1000) 
    }
    const handleApproveDocument = async(prospectDocId: number) => {
        try{
            setLoading(true);
            const payload = {
              "prospectDocId": prospectDocId,
              "approvalStatus": "Approved"
            }
            await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/prospectDoc/approveRejectNew`,
            payload);
          }catch( error) {
            console.error('Error in approving document:', error);
          }finally{
            setLoading(false);
          }
          message.success('Document has been approved');
          setTimeout(()=>{
            setReloadDocumentTab(Math.random());
          },1000) 
    }

    const handleCompleteReview = async() => {
      try{
          setLoading(true);
          await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/prospectDoc/submitReviewFeedbackNew?prospectId=${prospectId}&isReviewComplete=true`,
          {});
        }catch( error) {
          console.error('Error in Completing Review:', error);
        }finally{
          setLoading(false);
        }
        message.success('Review Completed');
        setTimeout(()=>{
          setReloadDocumentTab(Math.random());
        },1000) 
    }

   return ( 
            <Spin spinning={loader||loading} size="large">
                {
                    documents?.length ===0 ?
                        <div style={{display: 'flex', flexDirection:'column', alignItems:'center', opacity:`${loader?'20%':'100%'}`}}>
                            <NoDocuments />
                            <p style={{fontSize:'20px', fontWeight:'500', color:'#5C5C5C', marginLeft:'2rem'}}> No Documents Available </p>
                            <p style={{fontSize:'14px', fontWeight:'600', color:'#6C6C6C', marginLeft:'2rem'}}> You can add documents here after the prospect is at P2 : Proposal state </p>
                        </div> 
                        :
                        <>
                          <div style={{overflow: "scroll"}}>
                            <div style={{minWidth: 1100}}>
                              {
                                  allowedActionsForProspect?.has('approveOrRejectDocumentReview') ?
                                    <Row justify="space-between" className='document-list-item-container'>
                                      <Col style={{width:'10%'}}>Name</Col>
                                      <Col style={{width:'17%'}}>Upload / Download</Col>
                                      <Col style={{width:'8%'}}>Remarks</Col>
                                      <Col style={{width:'8%'}}>Feedback</Col>
                                      <Col style={{width:'15%'}}>Last updated at </Col>
                                      <Col style={{width:'12%'}}>Last updated by </Col>
                                      <Col style={{width:'10%'}}>Requested by </Col>
                                      {
                                        CREDIT_EDITABLE_DOCUMENT_STATE.has(docUploadStatus?.name) && <Col style={{width:'17%'}}>Action </Col>
                                      }
                                    </Row> :
                                    <Row justify="space-between" className='document-list-item-container' >
                                      <Col style={{width:'10%'}}>Name</Col>
                                      <Col style={{width:'17%'}}>Upload / Download</Col>
                                      <Col style={{width:'8%'}}>Remarks</Col>
                                      <Col style={{width:'8%'}}>Feedback</Col>
                                      <Col style={{width:'15%'}}>Last updated at </Col>
                                      <Col style={{width:'12%'}}>Last updated by </Col>
                                      <Col style={{width:'10%'}}>Requested by </Col>
                                    </Row>
                              }
                              
                              {
                                documents?.map((document:any) => 
                                    <DocumentItem key={document.id} document={document} setShowUpload={setShowUpload} 
                                        setDocumentActionTargetId={setDocumentActionTargetId} setShowViewDocs={setShowViewDocs} 
                                        handleRemarksNote={editProspectNote} handleFeedbackNote={editFeedbackNote} 
                                        handleRejectDocument={handleRejectDocument} handleApproveDocument={handleApproveDocument} 
                                        docUploadStatus={docUploadStatus} userRole={userRole} allowedActionsForProspect={allowedActionsForProspect}
                                        />)
                              }
                              {
                                showUpload &&
                                <UploadDocuments showUpload={showUpload} setShowUpload={setShowUpload} documentActionTargetId={documentActionTargetId} 
                                  setReloadDocumentTab={setReloadDocumentTab}/>
                              }
                              {
                                showViewDocs &&
                                <ViewDocuments showViewDocs={showViewDocs} setShowViewDocs={setShowViewDocs} 
                                  documentActionTargetId={documentActionTargetId} setShowUpload={setShowUpload} 
                                  docUploadStatus={docUploadStatus}
                                  setLoading={setLoading} userRole={userRole}
                                  setReloadDocumentTab={setReloadDocumentTab}
                                  allowedActionsForProspect={allowedActionsForProspect}
                                />
                              }
                              {
                                isTextEditorModalVisible && 
                                <RemarkAndFeedbackEditor
                                  visible = {isTextEditorModalVisible}
                                  title={editable ? "Edit Text" : viewerTitle}
                                  note = {noteText}
                                  setNoteText={setNoteText}
                                  onEditorClose = {onEditorClose}
                                  onSave = {onSave}
                                  editable={editable}
                                  setEditable={setEditable}
                                  placeholder={'Remarks'}
                                  showEditButton={allowedActionsForProspect?.has('addRemarks')}
                                  viewOnly={getDisableModifyButtonFlag(userRole, docUploadStatus?.name)}
                                />
                              }
                              
                              {
                                isFeedbackTextEditorModalVisible &&
                                <RemarkAndFeedbackEditor
                                visible = {isFeedbackTextEditorModalVisible}
                                title={feedbackEditable ? "Edit Text" : viewerFeedbackTitle}
                                note = {noteFeedbackText}
                                setNoteText={setNoteFeedbackText}
                                onEditorClose = {onFeedbackEditorClose}
                                onSave = {onFeedbackSave}
                                editable={feedbackEditable}
                                setEditable={setFeedbackEditable}
                                placeholder={'Feedback'}
                                showEditButton={allowedActionsForProspect?.has('feedback')}
                                isRejectFeedback={isRejectFeedback}
                                viewOnly={getDisableModifyButtonFlag(userRole, docUploadStatus?.name)}
                                />
                              }
                              
                              <CustomConfirmationModal
                                  visible={showConfirmRequest}
                                  title={''}
                                  message={'Are you sure you want to raise the Review Request for the documents ?'}
                                  confirmButtonLabel={`Raise Request`}
                                  cancelButtonLabel={`Cancel`}
                                  onClose={handleConfirmRequestCancel}
                                  onConfirm={handleConfirmRequestSave}
                                  />
                              <CustomConfirmationModalButtomColorReversed
                                  visible={showRejectRequestModal}
                                  title={''}
                                  message={'Are you sure you want to Reject the request for this document'}
                                  confirmButtonLabel={`Reject Request`}
                                  cancelButtonLabel={`Don't Reject`}
                                  onClose={handleRejectRequestCancel}
                                  onConfirm={handleRejectRequestSave}
                                  />
                              <CustomConfirmationModal
                                  visible={showConfirmFeedbackRequestModal}
                                  title={''}
                                  message={'Are you sure you want to move the documents to the Feedback submitted status?'}
                                  confirmButtonLabel={`Submit`}
                                  cancelButtonLabel={`Cancel`}
                                  onClose={handleSubmitFeedbackCancel}
                                  onConfirm={handleSubmitFeedbackSave}
                                  />
                              <CustomConfirmationModal
                                  visible={showConfirmCompleteRequestModal}
                                  title={''}
                                  message={'Are you sure you want to complete the Review Request for the documents ?'}
                                  confirmButtonLabel={`Complete Review`}
                                  cancelButtonLabel={`Cancel`}
                                  onClose={handleCompleteRequestCancel}
                                  onConfirm={handleCompleteRequestSave}
                                  />
                              <CustomConfirmationModal
                                  visible={showCancelRequestModal}
                                  title={''}
                                  message={'Are you sure you want to cancel the Review Request for the documents ?'}
                                  confirmButtonLabel={`Cancel Request`}
                                  cancelButtonLabel={`Don’t cancel`}
                                  onClose={handleCancelRequestCancel}
                                  onConfirm={handleCancelRequestSave}
                                  />                        
                            </div>
                          </div>

                          <div style={{display:'flex', justifyContent:'flex-end'}}>
                                {
                                    showCancelRequest 
                                    ?
                                        allowedActionsForProspect?.has('cancelReview') &&
                                        <div style={{display:'flex', justifyContent:'flex-end'}}>
                                            <Button icon={<Cancel/>}  onClick={()=>setShowCancelRequestModal(true)} 
                                                disabled={!showCancelRequest} 
                                                ghost
                                                danger
                                                className="button-override danger"
                                                >
                                                Cancel Request
                                            </Button>
                                        </div>
                                    :
                                    (reviewForRequestAllowed && allowedActionsForProspect?.has('requestReview')) ?
                                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                                        <Button onClick={()=> setShowConfirmRequest(true)} disabled={!reviewForRequestAllowed || stageId === 10} type="primary" className={!reviewForRequestAllowed || stageId === 10 ? "button-override disable" : "button-override"}>
                                            Request for Review
                                        </Button>
                                    </div>
                                    : ''
                                }
                                {
                                  allowedActionsForProspect?.has('submitFeedback') && showSubmitFeedback && 
                                  <div style={{marginLeft: 10}}>
                                            <Button   onClick={() => setShowConfirmFeedbackRequestModal(true)} 
                                                disabled = { !showSubmitFeedback }
                                                ghost
                                                type='primary'
                                                className={ !showSubmitFeedback ? 'button-override disable' : 'button-override primary' } 
                                                >
                                                Submit Feedback
                                            </Button>
                                  </div>
                                }
                                {
                                  allowedActionsForProspect?.has('completeFeedback')  && showCompleteReview && showCancelRequest && 
                                  <div style={{marginLeft: 10}}>
                                            <Button icon={<TickIcon/>}  onClick={() => setShowConfirmCompleteRequestModal(true)} 
                                                disabled={!showCompleteReview} 
                                                ghost
                                                type='primary'
                                                className={ !showCompleteReview ? 'button-override disable' : 'button-override primary' } 
                                                >
                                                Complete Review
                                            </Button>
                                  </div>
                                }
                          </div>
                        </>
                }
            </Spin>
        
    );
}

export default DocumentBody;