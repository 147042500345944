import React, { useState, useEffect, useRef } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { Button, Table, Spin, Modal, Select } from 'antd'; // Import Modal component from antd
import { LeftOutlined, LoadingOutlined, MinusOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { menuSelector } from '../../../../features/menu/menuSlice';
import getDynamicAction from '../../../../utils/helpers/dynamicAction';
import { userSelector } from '../../../../features/auth/userSlice';
import { ProspectInput } from '../../Prospect/utils/ProspectTypes';
import { fetchCreditAssessmentStatusData, fetchOwnerNamesDataFunctionForCreditAssessment, fetchProspectNamesDataFunction, fetchUpdatedByNamesForCreditAssessmentDataFunction } from '../../Prospect/utils/apiFunctions';
import { handlePageSizeChange, handlePageChange } from '../../Prospect/./utils/paginationFunctions';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useResizeEffect } from '../../Prospect/./utils/useResizeEffect';
import CustomSearchSelectFilter from '../../Prospect/utils/CustomSearchSelectFilter';
import { handleFileDownload } from '../../../../utils/handler/apiHandler';
import { Option } from "antd/es/mentions";
import _ from "lodash";
import '../../Prospect/styles.css';
import { fetchCreditAssessmentRequestTableData } from '.././utils/creditAssessmentRequestApiFunctions';
import { getAllCreditAssessmentRequestColumns } from './creditAllAssessmentRequestColumns';
import { removeParam, addOrUpdateParam } from "../../../../utils/queryParams"

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "#1677ff", marginLeft: 6 }} spin />;
const INITITAL_SEARCH_MODEL_HEIGHT: number= 100;

interface AxisState {
  XAxis: number;
  YAxis: number;
}

const AllCreditAssessmentRequest: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageCount, setPageCount] = useState<number>(0);
  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [prospectPageSize, setProspectPageSize] = useState<number>(10);
  const [prospectPageCount, setProspectPageCount] = useState<number>(0);
  const [prospectCurrentPage, setProspectCurrentPage] = useState<number>(1);
  const [ownerPageSize, setOwnerPageSize] = useState<number>(10);
  const [ownerPageCount, setOwnerPageCount] = useState<number>(0);
  const [ownerCurrentPage, setOwnerCurrentPage] = useState<number>(1);
  const [updatedByPageSize, setUpdatedByPageSize] = useState<number>(10);
  const [updatedByPageCount, setUpdatedByPageCount] = useState<number>(0);
  const [updatedByCurrentPage, setUpdatedByCurrentPage] = useState<number>(1);
  const [tableHeight, setTableHeight] = useState<number>(500);
  const [loading, setLoading] = useState<boolean>(false);
  const pageUrl = useLocation().pathname.split('/').pop();
  const menus = useAppSelector(menuSelector).menu;
  const actionItem = getDynamicAction(menus, pageUrl, 'prospect');
  const defaultData: ProspectInput[] = [];
  const [data, setData] = useState<ProspectInput[]>(defaultData);
  const [statusList, setStatusList] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [businessSegmentsList, setBusinessSegmentsList] = useState<any[]>([]);
  const [selectedBusinessSegments, setSelectedBusinessSegments] = useState<string[]>([]);
  const [prospectsList, setProspectsList] = useState<any[]>([]);
  const [selectedProspectsList, setSelectedProspectsList] = useState<any[]>([]);
  const [ownersList, setOwnersList] = useState<any[]>([]);
  const [selectedOwnersList, setSelectedOwnersList] = useState<any[]>([]);
  const [updatedByList, setUpdatedByList] = useState<any[]>([]);
  const [selectedUpdatedByList, setSelectedUpdatedByList] = useState<any[]>([]);
  const [prospectSearchText, setProspectSearchText] = useState<string>('');
  const [displayProspectSearchFilterScreen, setDisplayProspectSearchFilterScreen] = useState<boolean>(false);
  const [displayOwnerSearchFilterScreen, setDisplayOwnerSearchFilterScreen] = useState<boolean>(false);
  const [displayUpdatedBySearchFilterScreen, setDisplayUpdatedBySearchFilterScreen] = useState<boolean>(false);
  const [prospectModalHeight, setProspectModalHeight] = useState<number>(INITITAL_SEARCH_MODEL_HEIGHT);
  const [ownerModalHeight, setOwnerModalHeight] = useState<number>(INITITAL_SEARCH_MODEL_HEIGHT);
  const tableRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector(userSelector);
  const [updatedAtOrder, setUpdatedAtOrder] = useState<'ascending' | 'descending' | null>(null);
  const [requestedAtOrder, setRequestedAtOrder] = useState<'ascending' | 'descending' | null>(null);
  const [totalInvestmentOrder, setTotalInvestmentOrder] = useState<'ascending' | 'descending' | null>(null);
  const [prospectOwnerAxisState, setProspectOwnerAxisState] = useState<AxisState>({ XAxis: 0, YAxis: 0 });
  const [prospectNameAxisState, setProspectNameAxisState] = useState<AxisState>({ XAxis: 0, YAxis: 0 });
  const [updatedByAxisState, setUpdatedByAxisState] = useState<AxisState>({ XAxis: 0, YAxis: 0 });

  const [loader, setLoader] = useState(false);
  const getUpdatedAtSortOrder = () => `updatedAt:${updatedAtOrder === 'ascending' ? 'asc' : 'desc'}`;
  const getRequestedAtSortOrder = () => `requestedAt:${requestedAtOrder === 'ascending' ? 'asc' : 'desc'}`;
  const getTotalInvestmentSortOrder = () => `totalInvestmentValue:${totalInvestmentOrder === 'ascending' ? 'asc' : 'desc'}`;

  useEffect(() => {
    // Prospect IDs
    const prospectIds = (searchParams.get('prospectIds')?.split(",") || []).map(Number);
    setSelectedProspectsList(prospectIds);

    // Picked On Order
    const requestedAt = searchParams.get('requestedAtOrder');
    requestedAt === "ascending" ? setRequestedAtOrder("ascending") : requestedAt === "descending" ? setRequestedAtOrder("descending") : setRequestedAtOrder(null);

    // Picked On Order
    const prospectOwner = (searchParams.get('prospectOwner')?.split(",") || []);
    setSelectedOwnersList(prospectOwner);

    // Total Investment Order
    const totalInvestmentOrder = searchParams.get('totalInvestmentOrder');
    totalInvestmentOrder === "ascending" ? setTotalInvestmentOrder("ascending") : totalInvestmentOrder === "descending" ? setTotalInvestmentOrder("descending") : setTotalInvestmentOrder(null);

    // Updated By Order
    const updatedBy = (searchParams.get('updatedBy')?.split(",") || []);
    setSelectedUpdatedByList(updatedBy);

    // Updated By Order
    const updatedAt = searchParams.get('updatedAt');
    updatedAt === "ascending" ? setUpdatedAtOrder("ascending") : updatedAt === "descending" ? setUpdatedAtOrder("descending") : setUpdatedAtOrder(null);

    // Quote Status IDs
    const quote = searchParams.get('quoteStatusIds')?.split(",") || [];
    setSelectedStatus(quote);

    fetchCreditAssessmentStatusData(setStatusList);

    // Remove all filter query when back button in browser is clicked.
    const handlePopState = () => {
      // Refresh the page when the back button is clicked
      setSearchParams("")
      window.location.reload();
    };

    // Add the event listener for 'popstate'
    window.addEventListener("popstate", handlePopState);

    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    !_.isEmpty(selectedProspectsList) ? addOrUpdateParam("prospectIds", selectedProspectsList, searchParams, setSearchParams) : removeParam("prospectIds", searchParams, setSearchParams);
  }, [selectedProspectsList]);
  useEffect(() => {
    !_.isEmpty(requestedAtOrder) ? addOrUpdateParam("requestedAtOrder", requestedAtOrder, searchParams, setSearchParams) : removeParam("requestedAtOrder", searchParams, setSearchParams);
  }, [requestedAtOrder]);
  useEffect(() => {
    !_.isEmpty(selectedOwnersList) ? addOrUpdateParam("prospectOwner", selectedOwnersList, searchParams, setSearchParams) : removeParam("prospectOwner", searchParams, setSearchParams);
  }, [selectedOwnersList]);
  useEffect(() => {
    !_.isEmpty(totalInvestmentOrder) ? addOrUpdateParam("totalInvestmentOrder", totalInvestmentOrder, searchParams, setSearchParams) : removeParam("totalInvestmentOrder", searchParams, setSearchParams);
  }, [totalInvestmentOrder]);
  useEffect(() => {
    !_.isEmpty(updatedAtOrder) ? addOrUpdateParam("updatedAtOrder", updatedAtOrder, searchParams, setSearchParams) : removeParam("updatedAtOrder", searchParams, setSearchParams);
  }, [updatedAtOrder]);
  useEffect(() => {
    !_.isEmpty(selectedUpdatedByList) ? addOrUpdateParam("updatedBy", selectedUpdatedByList, searchParams, setSearchParams) : removeParam("updatedBy", searchParams, setSearchParams);
  }, [selectedUpdatedByList]);
  useEffect(() => {
    !_.isEmpty(selectedStatus) ? addOrUpdateParam("quoteStatusIds", selectedStatus, searchParams, setSearchParams) : removeParam("quoteStatusIds", searchParams, setSearchParams);
  }, [selectedStatus]);

  const isFirstLoad = useRef(true);
  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
      return;
    }
    const filterQueryString = getFilterQueryString();
    fetchCreditAssessmentRequestTableData(filterQueryString, setData, setPageCount, setTotalRecord, setLoading);
  }, [currentPage, pageSize, selectedStatus, selectedBusinessSegments, updatedAtOrder, requestedAtOrder, totalInvestmentOrder, selectedProspectsList, selectedOwnersList, selectedUpdatedByList]);

  useEffect(()=>{
    if(!displayOwnerSearchFilterScreen && selectedProspectsList.length===0)  
      setProspectsList([])
  }, [displayProspectSearchFilterScreen]);
  useEffect(()=>{
    if(!displayOwnerSearchFilterScreen && selectedOwnersList.length===0)  
      setOwnersList([])
  }, [displayOwnerSearchFilterScreen]);
  useEffect(()=>{
    if( selectedUpdatedByList.length===0)  
      setUpdatedByList([])
  }, [displayUpdatedBySearchFilterScreen]);
  
  
  useResizeEffect(tableRef, setTableHeight);
  
  function getFilterQueryString(): string {
    let filterQuery = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/allAssessment?page=${currentPage-1}&size=${pageSize}`;
    if(selectedProspectsList.length > 0){
      filterQuery += `&prospectIds=${selectedProspectsList}`
    }
    if(selectedOwnersList.length > 0){
      const prospectOwnerSSOIDList = selectedOwnersList.map( (ownerId:number) => 
        ownersList.find((owner:any) => owner.id === ownerId)?.ssoId
      )
      filterQuery += `&prospectOwners=${selectedOwnersList}`
    }
    if(selectedUpdatedByList.length > 0){
      const updatedBySSOIDList = selectedUpdatedByList.map( (ownerId:number) => 
        updatedByList.find((owner:any) => owner.id === ownerId)?.ssoId
      )
      console.log(selectedUpdatedByList);
      filterQuery += `&updatedByList=${selectedUpdatedByList}`
    }
    if(selectedStatus.length > 0){
      filterQuery += `&statusIds=${selectedStatus}`
    }
    let sortQuery = '&sort=';
    if(requestedAtOrder !== null){
      sortQuery += `${getRequestedAtSortOrder()}`
    }
    if(updatedAtOrder !== null){
      if(sortQuery!=='&sort=')
        sortQuery+= ',';
      sortQuery += `${getUpdatedAtSortOrder()}`
    }
    if(totalInvestmentOrder !== null){
      if(sortQuery!=='&sort=')
        sortQuery+= ',';
      sortQuery += `${getTotalInvestmentSortOrder()}`
    }
    if(sortQuery!=='&sort=')
      filterQuery += `${sortQuery}`
    return filterQuery;
  }

  const handlePageSizeChangeLocal = (current: number, size: number) => {
    handlePageSizeChange(current, size, setPageSize, setCurrentPage);
  };

  const handlePageChangeLocal = (page: number) => {
    handlePageChange(page, setCurrentPage);
  };

  const handleUpdatedAtOrder = () => {
    setUpdatedAtOrder(updatedAtOrder === null ? 'ascending' : updatedAtOrder ==='ascending' ? 'descending' : null );
  };
    
  const handleRequestedAtOrder = () => {
    setRequestedAtOrder(requestedAtOrder === null ? 'ascending' : requestedAtOrder ==='ascending' ? 'descending' : null );
  };
  const handleTotalInvestmentOrder = () => {
    setTotalInvestmentOrder(totalInvestmentOrder === null ? 'ascending' : totalInvestmentOrder ==='ascending' ? 'descending' : null );
  };

  const navigateToEdit = (link:any) => {
    navigate(link);
    window.location.reload();
  }

  const columns = getAllCreditAssessmentRequestColumns(
    statusList,
    businessSegmentsList,
    selectedStatus,
    selectedBusinessSegments,
    setSelectedStatus,
    setSelectedBusinessSegments,
    displayProspectSearchFilterScreen,
    setDisplayProspectSearchFilterScreen,
    setDisplayOwnerSearchFilterScreen,
    setDisplayUpdatedBySearchFilterScreen,
    navigateToEdit,
    updatedAtOrder,
    handleUpdatedAtOrder,
    requestedAtOrder,
    totalInvestmentOrder,
    handleTotalInvestmentOrder,
    handleRequestedAtOrder,
    setCurrentPage,
    selectedProspectsList,
    selectedOwnersList,
    selectedUpdatedByList,
    setProspectOwnerAxisState,
    setProspectNameAxisState,
    setUpdatedByAxisState,
  );

  const handleFetchProspectNames = async (searchKey: string, isInfiniteScroll: boolean) => {
    try {
      const data = await fetchProspectNamesDataFunction(
        searchKey,
        prospectCurrentPage,
        prospectPageSize,
        setProspectsList,
        setProspectCurrentPage,
        setProspectPageCount,
        isInfiniteScroll
      );
    } catch (error) {
      console.error('Error fetching prospect names:', error);
    }
  };

  const handleFetchOwnerNames = async (searchKey: string, isInfiniteScroll: boolean) => {
    try {
      const data = await fetchOwnerNamesDataFunctionForCreditAssessment(
        searchKey,
        ownerCurrentPage,
        ownerPageSize,
        setOwnersList,
        setOwnerCurrentPage,
        isInfiniteScroll
      );
    } catch (error) {
      console.error('Error fetching owner names:', error);
    }
  };

  const handleFetchUpdatedByNames = async (searchKey: string, isInfiniteScroll: boolean) => {
    try {
      const data = await fetchUpdatedByNamesForCreditAssessmentDataFunction(
        searchKey,
        updatedByCurrentPage,
        updatedByPageSize,
        setUpdatedByList,
        setUpdatedByCurrentPage,
        isInfiniteScroll
      );
    } catch (error) {
      console.error('Error fetching owner names:', error);
    }
  };

  const handleCancelModal = () => {
    setDisplayProspectSearchFilterScreen(false);
    setDisplayOwnerSearchFilterScreen(false);
    setDisplayUpdatedBySearchFilterScreen(false);
  }
  
  const rotatedMinusIconStyle = {
    transform: 'rotate(90deg)',
  };

  const handleProspectDownload = async () => {
    setLoader(true);
    const date = new Date();
    const doc_name = "Prospect_List_" + (date.getDate() + 1).toString().padStart(2, "0") + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getFullYear();
    const res = await handleFileDownload(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/downloadProspects`, doc_name, "xlsx");
    if(res === 200) setLoader(false);
  }

  return (
    <div style={{ backgroundColor: '#F6FAFF', height: '100%' }}>
      {
        displayProspectSearchFilterScreen &&
          (
            <Modal
              visible={displayProspectSearchFilterScreen}
              onCancel={handleCancelModal}
              footer={null}
              width={250}
              bodyStyle={{ }}
              style={{
                padding: "0px",
                width: "100px",
                maxHeight: 260,
                position: 'fixed',
                left: prospectNameAxisState.XAxis-180,
                top: prospectNameAxisState.YAxis+35,
              }}
              mask={false} // Prevents the background from being faded
              closable={false}
            >
              <CustomSearchSelectFilter
                placeholder="Enter 3 letters to search"
                fetchData={handleFetchProspectNames}
                selectedItems={selectedProspectsList}
                setSelectedItems={setSelectedProspectsList}
                items={prospectsList}
                setItems={setProspectsList}
                setDisplayProspectSearchFilterScreen={setDisplayProspectSearchFilterScreen}
                setCurrentPage={setProspectCurrentPage}
                currentPage={prospectCurrentPage}
                totalPageCount={prospectPageCount}
                setMainCurrentPage={setCurrentPage}
                modalHeight={prospectModalHeight}
                setModalHeight={setProspectModalHeight}
                searchKey="id"
                minSearchLength={3}
              />
            </Modal>
          )
      }
      {
        displayOwnerSearchFilterScreen &&
          <Modal
          visible={displayOwnerSearchFilterScreen}
          onCancel={handleCancelModal}
          footer={null}
          width={250}
          style={{
            padding: "0px",
            width: "100px",
            position: 'fixed',
            left: prospectOwnerAxisState.XAxis-180,
            top: prospectOwnerAxisState.YAxis+35,
          }}
          // wrapClassName="modal-wrapper-request-owner" 
          mask={false} // Prevents the background from being faded
          closable={false}
        >
          <CustomSearchSelectFilter
            placeholder="Enter 3 letters to search"
            fetchData={handleFetchOwnerNames}
            selectedItems={selectedOwnersList}
            setSelectedItems={setSelectedOwnersList}
            items={ownersList}
            setItems={setOwnersList}
            setDisplayProspectSearchFilterScreen={setDisplayOwnerSearchFilterScreen}
            setCurrentPage={setOwnerCurrentPage}
            currentPage={ownerCurrentPage}
            totalPageCount={ownerPageCount}
            setMainCurrentPage={setCurrentPage}
            modalHeight={ownerModalHeight}
            setModalHeight={setOwnerModalHeight}
            searchKey="ssoId"
            minSearchLength={3}
          />
        </Modal>
      }      
      {
        displayUpdatedBySearchFilterScreen &&
          <Modal
          visible={displayUpdatedBySearchFilterScreen}
          onCancel={handleCancelModal}
          footer={null}
          width={250}
          bodyStyle={{ }}
          style={{
            padding: "0px",
            width: "100px",
            position: 'fixed',
            left: updatedByAxisState.XAxis-150,
            top: updatedByAxisState.YAxis+35,
          }}
          mask={false} // Prevents the background from being faded
          closable={false}
        >
          <CustomSearchSelectFilter
            placeholder="Enter 3 letters to search"
            fetchData={handleFetchUpdatedByNames}
            selectedItems={selectedUpdatedByList}
            setSelectedItems={setSelectedUpdatedByList}
            items={updatedByList}
            setItems={setUpdatedByList}
            setDisplayProspectSearchFilterScreen={setDisplayUpdatedBySearchFilterScreen}
            setCurrentPage={setUpdatedByCurrentPage}
            currentPage={updatedByCurrentPage}
            totalPageCount={updatedByPageCount}
            setMainCurrentPage={setCurrentPage}
            modalHeight={ownerModalHeight}
            setModalHeight={setOwnerModalHeight}
            searchKey="ssoId"
            minSearchLength={3}
          />
        </Modal>
      }      
      
      <div ref={tableRef} style={{ height: 0.80 * tableHeight }}>
        <Spin spinning={loading} size="large">
          <Table
            columns={columns}
            dataSource={data || []}
            pagination={{
              pageSize: pageSize,
              position: ["bottomRight"],
              showSizeChanger: false, // Remove the default page size changer
              showTotal: (total, range) => (
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', marginTop:'-4px' }}>
                  <div style={{fontSize:'1rem'}}>
                    <span>Showing</span>
                    <Select defaultValue={10} style={{ width: 60, margin: '0 5px' }} onChange={(value) => handlePageSizeChangeLocal(1, value)}>
                      <Option value="10">10</Option>
                      <Option value="20">20</Option>
                      <Option value="50">50</Option>  
                      <Option value="100">100</Option>
                    </Select>
                    <span>Per Page</span>
                    <span style={{ marginRight:'10px', marginLeft:'10px', color:'grey'}}><MinusOutlined style={rotatedMinusIconStyle} /></span>
                    <span>{`Results: ${range[0]}-${range[1]} of ${total}`}</span>
                  </div>
                </div>
              ),
              onChange: handlePageChangeLocal,
              current: currentPage,
              total: totalRecord,
              itemRender: (current, type, originalElement) => {
                if (type === 'prev') {
                  return <LeftOutlined style={{marginLeft:'4rem'}}/>;
                }
                if (type === 'next') {
                  return  <RightOutlined />;
                }
                if (type === 'page') {
                  return (
                    <span style={{cursor: 'pointer' }} onClick={() => handlePageChangeLocal(current)}>
                      {current}
                    </span>
                  );
                }
                if (type === 'jump-next' || type === 'jump-prev') {
                  return <a style={{color: 'black'}}><strong> ... </strong></a>;
                }
                return originalElement;
              },
            }}            
            scroll={{ x: 'max-content', y: 0.80 * tableHeight }}
            size="middle"
            components={{
              header: {
                wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
              },
            }}
          />
        </Spin>
      </div>
    </div>
  );
};

export default AllCreditAssessmentRequest;
