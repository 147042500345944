import React, { useState, useEffect, useRef } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { Button, Table, Spin, Modal, Select } from 'antd'; // Import Modal component from antd
import { LeftOutlined, LoadingOutlined, MinusOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { menuSelector } from '../../../../features/menu/menuSlice';
import getDynamicAction from '../../../../utils/helpers/dynamicAction';
import { userSelector } from '../../../../features/auth/userSlice';
import { ProspectInput } from '../../Prospect/utils/ProspectTypes';
import { fetchInProgressCreditAssessmentStatusData, fetchPickedByDataFunctionForCreditAssessment, fetchProspectNamesDataFunction } from '../../Prospect/utils/apiFunctions';
import { handlePageSizeChange, handlePageChange } from '../../Prospect/./utils/paginationFunctions';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useResizeEffect } from '../../Prospect/./utils/useResizeEffect';
import CustomSearchSelectFilter from '../../Prospect/utils/CustomSearchSelectFilter';
import { handleFileDownload } from '../../../../utils/handler/apiHandler';
import { Option } from "antd/es/mentions";
import _ from "lodash";
import '../../Prospect/styles.css';
import { fetchCreditAssessmentRequestTableData } from '.././utils/creditAssessmentRequestApiFunctions';
import { getInProgressCreditAssessmentRequestColumns } from './creditInProgressAssessmentRequestColumns';
import { addOrUpdateParam, removeParam } from "../../../../utils/queryParams"

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "#1677ff", marginLeft: 6 }} spin />;
const INITITAL_SEARCH_MODEL_HEIGHT: number= 100;
interface AxisState {
  XAxis: number;
  YAxis: number;
}

const InProgressCreditAssessmentRequest: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageCount, setPageCount] = useState<number>(0);
  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [prospectPageSize, setProspectPageSize] = useState<number>(10);
  const [prospectPageCount, setProspectPageCount] = useState<number>(0);
  const [prospectCurrentPage, setProspectCurrentPage] = useState<number>(1);
  const [pickedByPageSize, setPickedByPageSize] = useState<number>(10);
  const [pickedByPageCount, setPickedByPageCount] = useState<number>(0);
  const [pickedByCurrentPage, setPickedByCurrentPage] = useState<number>(1);
  const [tableHeight, setTableHeight] = useState<number>(500);
  const [loading, setLoading] = useState<boolean>(false);
  const pageUrl = useLocation().pathname.split('/').pop();
  const menus = useAppSelector(menuSelector).menu;
  const actionItem = getDynamicAction(menus, pageUrl, 'prospect');
  const defaultData: ProspectInput[] = [];
  const [data, setData] = useState<ProspectInput[]>(defaultData);
  const [statusList, setStatusList] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [prospectsList, setProspectsList] = useState<any[]>([]);
  const [selectedProspectsList, setSelectedProspectsList] = useState<any[]>([]);
  const [pickedByList, setOwnersList] = useState<any[]>([]);
  const [selectedPickedByList, setPickedByOwnersList] = useState<any[]>([]);
  const [displayProspectSearchFilterScreen, setDisplayProspectSearchFilterScreen] = useState<boolean>(false);
  const [displayPickedBySearchFilterScreen, setDisplayPickedBySearchFilterScreen] = useState<boolean>(false);
  const [prospectModalHeight, setProspectModalHeight] = useState<number>(INITITAL_SEARCH_MODEL_HEIGHT);
  const [ownerModalHeight, setOwnerModalHeight] = useState<number>(INITITAL_SEARCH_MODEL_HEIGHT);
  const tableRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector(userSelector);
  const [pickedOnOrder, setPickedOnOrder] = useState<'ascending' | 'descending' | null>(null);
  const [etaOrder, setEtaOrder] = useState<'ascending' | 'descending' | null>(null);
  const [totalInvestmentOrder, setTotalInvestmentOrder] = useState<'ascending' | 'descending' | null>(null);
  const [loader, setLoader] = useState(false);
  const getPickedOnSortOrder = () => `pickedOn:${pickedOnOrder === 'ascending' ? 'asc' : 'desc'}`;
  const getETASortOrder = () => `eta:${etaOrder === 'ascending' ? 'asc' : 'desc'}`;
  const getTotalInvestmentSortOrder = () => `totalInvestmentValue:${totalInvestmentOrder === 'ascending' ? 'asc' : 'desc'}`;
  const [pickedByAxisState, setPickedByAxisState] = useState<AxisState>({ XAxis: 0, YAxis: 0 });
  const [prospectNameAxisState, setProspectNameAxisState] = useState<AxisState>({ XAxis: 0, YAxis: 0 });


  useEffect(() => {
    // Prospect IDs
    const prospectIds = (searchParams.get('prospectIds')?.split(",") || []).map(Number);
    setSelectedProspectsList(prospectIds);

    // Picked On Order
    const pickedOnOrder = searchParams.get('pickedOnOrder');
    pickedOnOrder === "ascending" ? setPickedOnOrder("ascending") : pickedOnOrder === "descending" ? setPickedOnOrder("descending") : setPickedOnOrder(null);

    // Picked By Order
    const pickedBy = (searchParams.get('pickedBy')?.split(",") || []);
    setPickedByOwnersList(pickedBy);

    // ETA Order
    const etaOrder = searchParams.get('etaOrder');
    etaOrder === "ascending" ? setEtaOrder("ascending") : etaOrder === "descending" ? setEtaOrder("descending") : setEtaOrder(null);

    // Total Investment Order
    const totalInvestmentOrder = searchParams.get('totalInvestmentOrder');
    totalInvestmentOrder === "ascending" ? setTotalInvestmentOrder("ascending") : totalInvestmentOrder === "descending" ? setTotalInvestmentOrder("descending") : setTotalInvestmentOrder(null);

    // Quote Status IDs
    const quote = searchParams.get('quoteStatusIds')?.split(",") || [];
    setSelectedStatus(quote);

    fetchInProgressCreditAssessmentStatusData(setStatusList);
    
    // Remove all filter query when back button in browser is clicked.
    const handlePopState = () => {
      // Refresh the page when the back button is clicked
      setSearchParams("")
      window.location.reload();
    };

    // Add the event listener for 'popstate'
    window.addEventListener("popstate", handlePopState);

    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    !_.isEmpty(selectedProspectsList) ? addOrUpdateParam("prospectIds", selectedProspectsList, searchParams, setSearchParams) : removeParam("prospectIds", searchParams, setSearchParams);
  }, [selectedProspectsList]);
  useEffect(() => {
    !_.isEmpty(pickedOnOrder) ? addOrUpdateParam("pickedOnOrder", pickedOnOrder, searchParams, setSearchParams) : removeParam("pickedOnOrder", searchParams, setSearchParams);
  }, [pickedOnOrder]);
  useEffect(() => {
    !_.isEmpty(selectedPickedByList) ? addOrUpdateParam("pickedBy", selectedPickedByList, searchParams, setSearchParams) : removeParam("pickedBy", searchParams, setSearchParams);
  }, [selectedPickedByList]);
  useEffect(() => {
    !_.isEmpty(etaOrder) ? addOrUpdateParam("etaOrder", etaOrder, searchParams, setSearchParams) : removeParam("etaOrder", searchParams, setSearchParams);
  }, [etaOrder]);
  useEffect(() => {
    !_.isEmpty(selectedStatus) ? addOrUpdateParam("quoteStatusIds", selectedStatus, searchParams, setSearchParams) : removeParam("quoteStatusIds", searchParams, setSearchParams);
  }, [selectedStatus]);

  useEffect(() => {
    !_.isEmpty(totalInvestmentOrder) ? addOrUpdateParam("totalInvestmentOrder", totalInvestmentOrder, searchParams, setSearchParams) : removeParam("totalInvestmentOrder", searchParams, setSearchParams);
  }, [totalInvestmentOrder]);

  const isFirstLoad = useRef(true);
  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
      return;
    }
    const filterQueryString = getFilterQueryString();
    fetchCreditAssessmentRequestTableData(filterQueryString, setData, setPageCount, setTotalRecord, setLoading);
  }, [currentPage, pageSize, selectedStatus, pickedOnOrder, etaOrder, totalInvestmentOrder, selectedProspectsList, selectedPickedByList]);


  useEffect(()=>{
    if(!displayPickedBySearchFilterScreen && selectedProspectsList.length===0)  
      setProspectsList([])
  }, [displayProspectSearchFilterScreen]);
  useEffect(()=>{
    if(!displayPickedBySearchFilterScreen && selectedPickedByList.length===0)  
      setOwnersList([])
  }, [displayPickedBySearchFilterScreen]);
  
  
  useResizeEffect(tableRef, setTableHeight);
  
  function getFilterQueryString(): string {
    let filterQuery = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/inProgressAssessment?page=${currentPage-1}&size=${pageSize}`;
    if(selectedProspectsList.length > 0){
      filterQuery = filterQuery + `&prospectIds=${selectedProspectsList}`
    }
    if(selectedPickedByList.length > 0){
      console.log(pickedByList, selectedPickedByList);

      const pickedBySSOIDList = selectedPickedByList.map( (pickedId:number) => 
        pickedByList.find((picked:any) => picked.ssoId === pickedId)?.ssoId
      )
      filterQuery += `&pickedByList=${pickedBySSOIDList}`
    }
    if(selectedStatus.length > 0){
      filterQuery = filterQuery + `&statusIds=${selectedStatus}`
    }
    if(pickedOnOrder !== null){
        filterQuery = filterQuery + `&sort=${getPickedOnSortOrder()}`
      }
    if(etaOrder !== null){
      filterQuery = filterQuery + `&sort=${getETASortOrder()}`
    }
    if(totalInvestmentOrder !== null){
      filterQuery = filterQuery + `&sort=${getTotalInvestmentSortOrder()}`
    }
    return filterQuery;
  }

  const handlePageSizeChangeLocal = (current: number, size: number) => {
    handlePageSizeChange(current, size, setPageSize, setCurrentPage);
  };

  const handlePageChangeLocal = (page: number) => {
    handlePageChange(page, setCurrentPage);
  };

  const handlePickedOnOrder = () => {
    setPickedOnOrder(pickedOnOrder === null ? 'ascending' : pickedOnOrder ==='ascending' ? 'descending' : null );
  };
    
  const handleETAOrder = () => {
    setEtaOrder(etaOrder === null ? 'ascending' : etaOrder ==='ascending' ? 'descending' : null );
  };
  const handleTotalInvestmentOrder = () => {
    setTotalInvestmentOrder(totalInvestmentOrder === null ? 'ascending' : totalInvestmentOrder ==='ascending' ? 'descending' : null );
  };

  const navigateToEdit = (link:any) => {
    navigate(link);
    window.location.reload();
  }

  const columns = getInProgressCreditAssessmentRequestColumns(
    statusList,
    selectedStatus,
    setSelectedStatus,
    displayProspectSearchFilterScreen,
    setDisplayProspectSearchFilterScreen,
    setDisplayPickedBySearchFilterScreen,
    navigateToEdit,
    pickedOnOrder,
    handlePickedOnOrder,
    etaOrder,
    totalInvestmentOrder,
    handleTotalInvestmentOrder,
    handleETAOrder,
    setCurrentPage,
    selectedProspectsList,
    selectedPickedByList,
    setProspectNameAxisState,
    setPickedByAxisState,

  );

  const handleFetchProspectNames = async (searchKey: string, isInfiniteScroll: boolean) => {
    try {
      const data = await fetchProspectNamesDataFunction(
        searchKey,
        prospectCurrentPage,
        prospectPageSize,
        setProspectsList,
        setProspectCurrentPage,
        setProspectPageCount,
        isInfiniteScroll
      );
    } catch (error) {
      console.error('Error fetching prospect names:', error);
    }
  };

  const handlePickedByNames = async (searchKey: string, isInfiniteScroll: boolean) => {
    try {
      const data = await fetchPickedByDataFunctionForCreditAssessment(
        searchKey,
        pickedByCurrentPage,
        pickedByPageSize,
        setOwnersList,
        setPickedByCurrentPage,
        isInfiniteScroll
      );
    } catch (error) {
      console.error('Error fetching picked by names:', error);
    }
  };

  
  const handleCancelModal = () => {
    setDisplayProspectSearchFilterScreen(false);
    setDisplayPickedBySearchFilterScreen(false);
  }
  
  const rotatedMinusIconStyle = {
    transform: 'rotate(90deg)',
  };

  const handleProspectDownload = async () => {
    setLoader(true);
    const date = new Date();
    const doc_name = "Prospect_List_" + (date.getDate() + 1).toString().padStart(2, "0") + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getFullYear();
    const res = await handleFileDownload(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/downloadProspects`, doc_name, "xlsx");
    if(res === 200) setLoader(false);
  }

  return (
    <div style={{ backgroundColor: '#F6FAFF', height: '100%'}}>
      {
        displayProspectSearchFilterScreen &&
          (
            <Modal
              visible={displayProspectSearchFilterScreen}
              onCancel={handleCancelModal}
              footer={null}
              width={250}
              bodyStyle={{ }}
              style={{
                padding: "0px",
                width: "100px",
                maxHeight: 260,
                position: 'fixed',
                left: prospectNameAxisState.XAxis-180,
                top: prospectNameAxisState.YAxis+35,
              }}
              
              mask={false} // Prevents the background from being faded
              closable={false}
            >
              <CustomSearchSelectFilter
                placeholder="Enter 3 letters to search"
                fetchData={handleFetchProspectNames}
                selectedItems={selectedProspectsList}
                setSelectedItems={setSelectedProspectsList}
                items={prospectsList}
                setItems={setProspectsList}
                setDisplayProspectSearchFilterScreen={setDisplayProspectSearchFilterScreen}
                setCurrentPage={setProspectCurrentPage}
                currentPage={prospectCurrentPage}
                totalPageCount={prospectPageCount}
                setMainCurrentPage={setCurrentPage}
                modalHeight={prospectModalHeight}
                setModalHeight={setProspectModalHeight}
                searchKey="id"
                minSearchLength={3}
              />
            </Modal>
          )
      }
      {
        displayPickedBySearchFilterScreen &&
          <Modal
          visible={displayPickedBySearchFilterScreen}
          onCancel={handleCancelModal}
          footer={null}
          width={250}
          bodyStyle={{ }}
          style={{
            padding: "0px",
            width: "100px",
            position: 'fixed',
            left: pickedByAxisState.XAxis-160,
            top: pickedByAxisState.YAxis+35,
          }}
          mask={false} // Prevents the background from being faded
          closable={false}
        >
          <CustomSearchSelectFilter
            placeholder="Enter 3 letters to search"
            fetchData={handlePickedByNames}
            selectedItems={selectedPickedByList}
            setSelectedItems={setPickedByOwnersList}
            items={pickedByList}
            setItems={setOwnersList}
            setDisplayProspectSearchFilterScreen={setDisplayPickedBySearchFilterScreen}
            setCurrentPage={setPickedByCurrentPage}
            currentPage={pickedByCurrentPage}
            totalPageCount={pickedByPageCount}
            setMainCurrentPage={setCurrentPage}
            modalHeight={ownerModalHeight}
            setModalHeight={setOwnerModalHeight}
            searchKey="ssoId"
            minSearchLength={3}
          />
        </Modal>
      }      
      
      <div ref={tableRef} style={{ height: 0.80 * tableHeight }}>
        <Spin spinning={loading} size="large">
          <Table
            columns={columns}
            dataSource={data || []}
            pagination={{
              pageSize: pageSize,
              position: ["bottomRight"],
              showSizeChanger: false, // Remove the default page size changer
              showTotal: (total, range) => (
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', marginTop:'-4px' }}>
                  <div style={{fontSize:'1rem'}}>
                    <span>Showing</span>
                    <Select defaultValue={10} style={{ width: 60, margin: '0 5px' }} onChange={(value) => handlePageSizeChangeLocal(1, value)}>
                      <Option value="10">10</Option>
                      <Option value="20">20</Option>
                      <Option value="50">50</Option>  
                      <Option value="100">100</Option>
                    </Select>
                    <span>Per Page</span>
                    <span style={{ marginRight:'10px', marginLeft:'10px', color:'grey'}}><MinusOutlined style={rotatedMinusIconStyle} /></span>
                    <span>{`Results: ${range[0]}-${range[1]} of ${total}`}</span>
                  </div>
                </div>
              ),
              onChange: handlePageChangeLocal,
              current: currentPage,
              total: totalRecord,
              itemRender: (current, type, originalElement) => {
                if (type === 'prev') {
                  return <LeftOutlined style={{marginLeft:'4rem'}}/>;
                }
                if (type === 'next') {
                  return  <RightOutlined />;
                }
                if (type === 'page') {
                  return (
                    <span style={{cursor: 'pointer' }} onClick={() => handlePageChangeLocal(current)}>
                      {current}
                    </span>
                  );
                }
                if (type === 'jump-next' || type === 'jump-prev') {
                  return <a style={{color: 'black'}}><strong> ... </strong></a>;
                }
                return originalElement;
              },
            }}            
            scroll={{ x: 'max-content', y: 0.80 * tableHeight }}
            size="middle"
            components={{
              header: {
                wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
              },
            }}
          />
        </Spin>
      </div>
    </div>
  );
};

export default InProgressCreditAssessmentRequest;