import { EditOutlined, EyeOutlined, LeftOutlined, SwapOutlined } from "@ant-design/icons";
import { Button, Space, Tabs, Tag, Tooltip, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { modalButtonStyle } from "./OnboardNewClient";
import type { TabsProps } from 'antd';
import OnboardingDetail from "./OnboardingDetail";
import AgreementDocuments from "./AgreementDocuments";
import { useEffect, useState } from "react";
import { ProspectDetailsType } from "./types/Prospect";
import { getRequest } from "../../../utils/handler/apiHandler";
import { getStageStyles } from "../Prospect/utils/stageUtils";
import { useAppSelector } from "../../../app/hooks";
import { menuSelector } from "../../../features/menu/menuSlice";

export default function ClientDetails() {
    const navigate = useNavigate();
    const [prospectDetails, setProspectDetails] = useState<ProspectDetailsType | null>(null)
    const [loading, setLoading] = useState(true)
    const menuActions = useAppSelector(menuSelector).menuActions;
    const allowedActionsForClientList: string[] | undefined = menuActions?.['Client'];
    const allowedActionsForClient = new Set(allowedActionsForClientList);
    const url = useLocation();

    const clientID = url.pathname.split("/").pop();

    const getProspectDetails = async () => {
        setLoading(true)
        try {
            const response = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/getClientDetails/${clientID}`);
            console.log("response.data", response.data)
            setProspectDetails(response.data)
            // if (response.data.clientStateStatus == "On Boarding Completed") {
            //     setShowProspectExistsModal(true)
            // } else {
            //     viewDetails(response.data)
            // }
        } catch (error) {
            console.error("Error fetching data", error);
        }
        setLoading(false)
    }


    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Onboarding Detail',
            children: <OnboardingDetail prospectDetails={prospectDetails} getProspectDetails={getProspectDetails} setProspectDetails={setProspectDetails} allowedActionsForClient={allowedActionsForClient} />,
        },
        {
            key: '2',
            label: 'Documents',
            children: <AgreementDocuments prospectDetails={prospectDetails} allowedActionsForClient={allowedActionsForClient} />
        },
    ];

    useEffect(() => {
        console.log("clientID", clientID)
        getProspectDetails()
    }, [])



    return (
        <div className="parent-container">
            <div className="container-layout">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                            <LeftOutlined style={{ margin: 0, padding: 0 }} onClick={_ => navigate("/dashboard/client-onboarding")} />
                            <Tooltip title={"Client Name"} placement="bottomLeft">
                                <Typography.Title level={4} style={{ margin: 0, padding: 0 }}>
                                    {prospectDetails?.prospect.name}
                                </Typography.Title>
                            </Tooltip>
                            <Tag bordered={false} color={getStageStyles(prospectDetails?.prospect.stage?.id as any).backgroundColor} style={{ padding: "0 8px", fontWeight: 600, fontSize: 12, height: 20, color: getStageStyles(prospectDetails?.prospect.stage?.id as any).color }}>{prospectDetails?.prospect?.stage?.name}</Tag>

                            {/* {
                                <EditOutlined style={{ color: "#2C66E3", cursor: "pointer" }} />
                            } */}
                        </div>
                        <div style={{ display: "flex", marginTop: 4 }}>
                            <Typography style={{ width: "auto", marginRight: 5, fontSize: 12 }}>Owned by: {prospectDetails?.prospect?.owner && JSON.parse(prospectDetails?.prospect?.owner as string)?.name}</Typography>

                            {/* <SwapOutlined style={{ color: "#2C66E3" }} /> */}
                        </div>
                    </div>


                </div>

                <Tabs style={{ marginTop: 20 }} defaultActiveKey="1" items={items} />
            </div>
        </div>
    )
}