import { CheckOutlined, CloseOutlined, DeleteFilled, DeleteOutlined, DownloadOutlined, EditOutlined, EyeInvisibleFilled, EyeOutlined, FileOutlined, PlusOutlined, SaveOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Divider, Input, Modal, Row, Skeleton, Space, Tabs, Tooltip, Typography, Upload } from "antd";
import { modalButtonStyle } from "./OnboardNewClient";
import { getRequest, getRequestForDownload, postRequest } from "../../../utils/handler/apiHandler";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useAppSelector } from "../../../app/hooks";
import { authSelector } from "../../../features/auth/authSlice";
import axios from "axios";
import DocumentRemarks from "./DocumentRemarks";
import ViewDocumentModal from "./ViewDocumentModal";
import "./upload.css"

export default function AgreementDocuments({ prospectDetails, allowedActionsForClient }: any) {

    const [docType, setDocTypes] = useState([])
    const [showDocsModal, setShowDocsModal] = useState<boolean>(false)
    const [documents, setDocuments] = useState({})
    const [showAddNewModal, setShowAddNewModal] = useState(false)
    const [newField, setNewField] = useState("")
    const [submittingNew, setSubmittingNew] = useState(false)
    const [addNewDoc, setAddNewDoc] = useState(false)
    const [fileList, setFileList] = useState<any>([])
    const [fileUrls, setFileUrls] = useState<any>([])
    const [selectedDocType, setSelectedDocType] = useState<any>(null)
    const [selectedId, setSelectedId] = useState<number | null>(null)
    const [editingItem, setEditingItem] = useState<any>(null)
    const [udpatingValitidy, setUpdateValidity] = useState(false)
    const [previewFile, setPreviewFile] = useState<null | {
        "fileName": string,
        "type": string,
        "url": string,
    }>(null)
    const [loading, setLoading] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [ongoingUploads, setOngoingUploads] = useState(0);

    const auth = useAppSelector(authSelector);


    const getDocTypes = async () => {
        setLoading(true)
        try {
            const response = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/getAgreementDocs?clientOnBoardingId=${prospectDetails?.clientOnBoardingId}`);
            console.log("response.data doc types", response.data)
            setDocTypes(response.data)
        } catch (error) {
            console.error("Error fetching data", error);
        }
        setLoading(false)
    }

    const handleShowDocs = async (id: number) => {
        try {
            const response = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/viewFiles?agreementDocId=${id}`);
            console.log("response.data", response.data)
            setDocuments(response.data)
            
        } catch (error) {
            console.error("Error fetching data", error);
        }
        setSelectedId(id)
        setShowDocsModal(true)
    }

    useEffect(() => {
        getDocTypes()
    }, [])

    const handleAddNewDoc = () => {
        console.log("add new", newField)
        setShowAddNewModal(false)
        addDocTypes()
    }

    useEffect(() => {
        setNewField("")
    }, [showAddNewModal])

    const addDocTypes = async () => {
        setSubmittingNew(true)
        let typesToAdd = [
            {
                "id": 6,
                "name": newField,
                "isOtherDocType": true,
                "allowCancel": true,
                "active": true
            }
        ]

        if (typesToAdd.length > 0) {
            await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/addAgreementDoc?clientOnBoardingId=${prospectDetails?.clientOnBoardingId}`, typesToAdd).then(res => {
                console.log(res)
                setSubmittingNew(false)
                setShowAddNewModal(false)
                getDocTypes()

            })
        }
    }


    const handleUploadRequest = async (file: any) => {
        const url = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/uploadAgreementDoc?prospectId=${prospectDetails?.prospect?.prospectId}&isAddressDoc=false`;
        const formData = new FormData();
        formData.append('file', file.file);
        setUploading(true)
        setOngoingUploads((prev) => prev + 1);
        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Authorization': `Bearer ${auth.accessToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                console.log(response)
                console.log("response?.data", response?.data)

                if (response.status === 200) {
                    setFileUrls((prevState: any) => [...prevState, response?.data?.DocumentUrl])
                    setFileList((prevState: any) => [...prevState, file.file]);
                }
                else {
                    console.log("error in uploading file")
                }
            })
        } catch (error) {
            console.error('Upload error:', error);
        } finally {
            setOngoingUploads((prev) => {
                const newOngoingUploads = prev - 1;
                if (newOngoingUploads === 0) {
                    setUploading(false);
                }
                return newOngoingUploads;
            });
        }
    };

    const handleDelete = (file: any) => {

        const tempFileList = [];
        const tempFileUrls: string[] = []
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i]?.uid !== file?.uid) {
                tempFileUrls.push(fileUrls?.[i])
                tempFileList.push(fileList[i]);
            }
        }
        setFileUrls(tempFileUrls)
        setFileList(tempFileList);
    }
    const handleDownloadAllClick = async () => {
        try {
            const res = await getRequestForDownload(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/downloadAll?agreementDocId=${selectedId}`,
                'application/zip'
            );
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `downloadedDocumentId${selectedId}.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading all documents:', error);
        }
    };

    const handleUploadDoc = async () => {
        console.log(selectedDocType, fileUrls)
        const docParams = [
            {
                clientAgreementDocumentId: selectedId,
                validity: selectedDocType.validity || null, // Default or from selectedDocType
                remarks: selectedDocType.remark || null, // Default or from selectedDocType
                uploadedDocUrl: fileUrls.map((url: any) => {
                    const fileName = url.substring(url.lastIndexOf('/') + 1).split('.')[0]; // Get file name without extension
                    const fileType = url.split('.').pop(); // Get file extension

                    return {
                        fileName: fileName,
                        url: url,
                        type: fileType
                    };
                })
            }
        ];

        console.log(docParams);

        await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/uploadAgreementDocDetails`, docParams).then((res: any) => {
            console.log(res)
            setAddNewDoc(false)
            setFileList([])
            setFileUrls([])
            handleShowDocs(selectedDocType.id)
        })
    }

    const handleUpdateValidity = async (item: any) => {
        setUpdateValidity(true)
        const inputElement = document.getElementById(`validity-${item.id}`) as HTMLInputElement;

        if (inputElement) {
            const inputValue: string = inputElement.value;
            console.log(inputValue);
            const docParams = [
                {
                    clientAgreementDocumentId: item.id,
                    validity: inputValue || null, // Default or from selectedDocType
                    remarks: item.remark || null, // Default or from selectedDocType
                    uploadedDocUrl: []
                }];
            await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/uploadAgreementDocDetails`, docParams).then((res: any) => {
                console.log(res)
                getDocTypes()
                setEditingItem(null)
                
            }).catch(e => {
                console.log(e)
            })
        } else {
            console.error('Input element not found');
        }
        setUpdateValidity(false)
    }

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: 40 }}>
                <div>
                    <Typography.Text strong style={{ fontSize: 16 }}>Agreement Documents</Typography.Text>
                </div>
                <div>
                    {allowedActionsForClient.has("addAgreement") && <Button block style={modalButtonStyle} onClick={() => setShowAddNewModal(true)}>
                        <PlusOutlined /> Add New Agreement
                    </Button>}
                </div>
            </div>

            <Row style={{ padding: "12px 20px", borderRadius: 12 }}>
                <Col span={5}>
                    <Typography.Text strong>Document</Typography.Text>
                </Col>
                {allowedActionsForClient.has("updateAgreementDetails") && <Col span={5}>
                    <Typography.Text strong>Validity</Typography.Text>
                </Col>}
                <Col span={4}>
                    <Typography.Text strong>Updated at</Typography.Text>
                </Col>
                <Col span={5}>
                    <Typography.Text strong>Updated By</Typography.Text>
                </Col>
                <Col span={5}>
                    <Typography.Text strong>Action</Typography.Text>
                </Col>
            </Row>

            {loading ? <>
                <Skeleton active={true} paragraph={false} loading={true} style={{ marginTop: 10 }} />
                <Skeleton active={true} paragraph={false} loading={true} style={{ marginTop: 10 }} />
                <Skeleton active={true} paragraph={false} loading={true} style={{ marginTop: 10 }} />
                <Skeleton active={true} paragraph={false} loading={true} style={{ marginTop: 10 }} />
                <Skeleton active={true} paragraph={false} loading={true} style={{ marginTop: 10 }} />

            </> : <>
                {docType?.map((item: any) => {
                    return <Row style={{ background: "#E6F2FD", padding: "12px 20px", borderRadius: 12, marginTop: 8, alignItems: "center" }}>
                        <Col span={5}>
                            <Typography.Text>{item.name}</Typography.Text>
                        </Col>
                        {allowedActionsForClient.has("updateAgreementDetails") && <Col span={5}>
                            <div style={{ display: "flex" }}>
                                {editingItem?.id != item.id && item.validity}
                                {editingItem?.id != item.id && <EditOutlined onClick={() => setEditingItem(item)} style={{ color: "#2C66E3", cursor: "pointer", marginLeft: 8 }} />}
                                {editingItem?.id == item.id && <DatePicker id={`validity-${item.id}`} size="small" defaultValue={item.validity ? dayjs(item.validity, "YYYY-MM-DD") : null} />}
                                {editingItem?.id == item.id && <Space style={{ marginLeft: 8 }} size="small">
                                    <Button disabled={udpatingValitidy} size="small" onClick={() => setEditingItem(null)} type="text" icon={<CloseOutlined style={{ fontSize: 12, color: "#FF4D4F" }} />} />

                                    <Divider type="vertical" />

                                    <Button loading={udpatingValitidy} disabled={udpatingValitidy} size="small" onClick={() => handleUpdateValidity(item)} type="text" icon={<CheckOutlined style={{ fontSize: 12, color: "#02B51F" }} />} />


                                </Space>}
                            </div>
                        </Col>}
                        <Col span={4}>
                            <Typography.Text>{dayjs(item.updatedAt, "YYYY-MM-DD").format("YYYY-MM-DD")}</Typography.Text>
                        </Col>
                        <Col span={5}>
                            <Typography.Text>{JSON.parse(item?.updatedBy)?.name}</Typography.Text>
                        </Col>
                        <Col span={5}>
                            <div onClick={() => { setSelectedDocType(item); handleShowDocs(item?.id) }} style={{ display: "flex", gap: 4, alignItems: "center", cursor: "pointer" }}>
                                <img width={9} src={`${__dirname}assets/document.png`} />
                                <Typography.Text style={{ color: "#1777FF" }}>View Docs</Typography.Text>
                            </div>
                        </Col>
                    </Row>
                })}
            </>}

            <Modal
                destroyOnClose
                centered style={{ textAlign: "center" }}
                footer={null}
                onCancel={() => { setShowDocsModal(false); setAddNewDoc(false); setFileList([]); setFileUrls([]) }}
                closable={true}
                open={showDocsModal}
                styles={{
                    content: {
                        background: "#F6FAFF",
                    },
                    body: {
                        background: "#F6FAFF",
                        margin: 0,
                        padding: 0
                    }
                }}
            >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: 20 }}>
                    <div>
                        <Typography.Text strong>
                            Documents
                        </Typography.Text>
                    </div>
                    {!addNewDoc && <div style={{ display: "flex", gap: 8 }}>
                        {Object?.keys(documents).length !== 0 && <Button onClick={() => handleDownloadAllClick()} style={modalButtonStyle}>
                            <DownloadOutlined /> Download All
                        </Button>}

                        {allowedActionsForClient.has("updateAgreementDetails") && <Button onClick={() => setAddNewDoc(true)} style={modalButtonStyle}>
                            <PlusOutlined /> Add New Doc
                        </Button>}
                    </div>}
                </div>
                <br />
                {addNewDoc &&
                    <>
                        <Upload
                            accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
                            style={{ width: "100%", display: "block" }}
                            customRequest={(file) => handleUploadRequest({ file })}
                            beforeUpload={(file) => {
                                handleUploadRequest({ file })
                                return false
                            }}
                            fileList={fileList}
                            onRemove={handleDelete}
                            itemRender={(originNode, file, fileList2, actions) => <div style={{ display: "flex", justifyContent: "space-between", marginTop: 8 }}>
                                <div style={{ width: 150, textAlign: "left" }}>
                                    <Typography.Text ellipsis>
                                        <Tooltip title={file.name}>
                                            {file.name}
                                        </Tooltip>
                                    </Typography.Text>
                                </div>
                                <div style={{ display: "flex", gap: 10 }}>
                                    {["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(file.type as string) ? <div onClick={() => {
                                        const itemIndex = fileList2.findIndex((i) => i.uid === file.uid);
                                        setPreviewFile({
                                            "fileName": file.name,
                                            "type": file.type as string,
                                            "url": fileUrls[itemIndex],
                                        })
                                    }} style={{ cursor: "pointer" }}>
                                        <EyeOutlined />
                                    </div> :
                                        <EyeInvisibleFilled style={{ color: "grey" }} />
                                    }

                                    <Button disabled={uploading} onClick={actions.remove} size="small" type="text" style={{ cursor: "pointer" }}>
                                        <DeleteFilled style={{ color: "red" }} />
                                    </Button>
                                </div>


                            </div>}
                            listType="text"
                            multiple={true}
                        >

                            <div>
                                <Button
                                    loading={uploading}
                                    disabled={uploading}
                                    size="large"
                                    block
                                    type="dashed"
                                    style={{ width: "100%" }}
                                    icon={<UploadOutlined />}
                                >
                                    Select Files
                                </Button>
                            </div>

                        </Upload>

                        <div style={{ textAlign: "right" }}>
                            <Button disabled={uploading} onClick={() => { setFileList([]); setFileUrls([]); setAddNewDoc(false) }} style={{ ...modalButtonStyle, color: "red", borderColor: "red" }}>
                                <CloseOutlined /> Cancel
                            </Button>
                            <Button
                                disabled={fileList.length == 0 || uploading}
                                onClick={handleUploadDoc} style={{ marginTop: 20, marginLeft: 8 }} type="primary">Upload</Button>

                        </div>
                    </>}

                {!addNewDoc && <Tabs defaultActiveKey="1" items={[{
                    key: '1',
                    label: 'Documents',
                    children: <Files documents={documents} setPreviewFile={setPreviewFile} allowedActionsForClient={allowedActionsForClient} />,
                },
                {
                    key: '2',
                    label: 'Remarks',
                    children: <DocumentRemarks setSelectedDocType={setSelectedDocType} getDocTypes={getDocTypes} docType={docType} selectedDocType={selectedDocType} allowedActionsForClient={allowedActionsForClient} />,
                },]} />}


            </Modal>

            <Modal title="Agreement Name" footer={
                <div style={{ display: "flex", gap: 12, marginTop: 24 }}>
                    <Button loading={submittingNew} disabled={submittingNew || !newField} size="large" style={{ ...modalButtonStyle, width: "100%" }} onClick={handleAddNewDoc}>
                        Submit
                    </Button>
                </div>
            }
                centered style={{ textAlign: "center" }}
                styles={{
                    footer: {
                        paddingInline: "10px",
                        marginBottom: 16
                    },
                    header: {
                        textAlign: "left"
                    }
                }}
                onCancel={() => { setShowAddNewModal(false); setNewField("") }}
                closable={true}
                open={showAddNewModal}
                destroyOnClose
            >

                <Typography style={{ fontSize: 14, marginTop: 40 }}>
                    <Input placeholder="Enter agreement name" size="large" onChange={(e) => setNewField(e.target.value)} />
                </Typography>

            </Modal>

            <ViewDocumentModal setPreviewFile={setPreviewFile} previewFile={previewFile} />

        </>
    )
}

const handleDownlaod = async (url: string) => {
    console.log(url)
    const fileName = url.substring(url.lastIndexOf('/') + 1).replace(/%20/g, ' ')

    try {
        const response = await fetch(url);
        const blob = await response.blob();
        console.log(blob)
        const downloadUrl = window.URL.createObjectURL(blob);

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName);  // Specify the file name dynamically

        // Append the anchor element to the body
        document.body.appendChild(link);

        // Create a click event
        const event = new MouseEvent('click');
        link.dispatchEvent(event);

        // Clean up: remove the anchor element and revoke the object URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};

const Files = ({ documents, setPreviewFile }: any) => {
    return (
        <>

            <div style={{ maxHeight: 300, overflow: "scroll" }}>
                {Object.entries(documents).map(([date, docs]: any) => (
                    <div style={{ textAlign: "left" }} key={date}>
                        <Typography.Text style={{ marginTop: 8, display: "block" }} strong>{dayjs(date, "YYYY-MM-DD").format("DD MMM YYYY")}</Typography.Text>
                        <div>
                            {docs.map((doc: any) => (
                                <div style={{ background: "white", padding: 12, display: "flex", justifyContent: "space-between", borderRadius: 12, marginTop: 12, boxShadow: "0px 4px 24px 0px #0000000D" }} key={doc.id}>
                                    <div style={{ width: 150 }}>
                                        <Typography.Text ellipsis>
                                            <Tooltip title={`${doc.fileName}.${doc.type}`}>
                                                {doc.fileName}.{doc.type}
                                            </Tooltip>
                                        </Typography.Text>
                                    </div>
                                    <Space size="small">
                                        <Button size="small" type="text" style={{ color: "#2C66E3" }} onClick={() => handleDownlaod(doc.url)}><SaveOutlined /> Download</Button>
                                        <div>
                                            {["jpeg", "jpg", "png", "pdf"].includes(doc.type) ? <div onClick={() => setPreviewFile({
                                                "fileName": doc.fileName,
                                                "type": doc.type,
                                                "url": doc.url,
                                            })} style={{ cursor: "pointer" }}><EyeOutlined style={{ color: "#2C66E3" }} /> <Typography.Text style={{ color: "#2C66E3" }}>Preview</Typography.Text></div> :
                                                <div>
                                                    <EyeInvisibleFilled style={{ color: "grey" }} />
                                                </div>
                                            }
                                        </div>
                                    </Space>
                                </div>
                            ))}

                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}