import { Button, Checkbox, Col, DatePicker, Divider, Empty, Form, Input, InputNumber, message, Modal, Radio, Row, Select, Space, Spin, Switch, Tooltip, Typography, Upload } from "antd";
import "./form.css"
import { useEffect, useState } from "react";
import { getRequest, postRequest, putRequest } from "../../../utils/handler/apiHandler";
import { CheckOutlined, CloseOutlined, DeleteFilled, DeleteOutlined, DownCircleOutlined, EditOutlined, EyeInvisibleFilled, EyeInvisibleOutlined, EyeOutlined, FileOutlined, LoadingOutlined, PlusOutlined, RightOutlined, SaveOutlined, UploadOutlined } from "@ant-design/icons";
import { ProspectDetailsType } from "./types/Prospect";
import axios from "axios";
import { useAppSelector } from "../../../app/hooks";
import { authSelector } from "../../../features/auth/authSlice";
import dayjs from "dayjs";
import moment from "moment";
import { modalButtonStyle } from "./OnboardNewClient";
import VersionHistory from "./VersionHistory";
import SelectSearch from "../../../utils/SelectSearch";
import ViewDocumentModal from "./ViewDocumentModal";
import ShowCertificates from "./ShowCertificates";
const HARYANA_STATE_ID: number = 6;


const antIconUpload = <LoadingOutlined style={{ fontSize: 16, color: "black", marginLeft: 6 }} spin />;


export const handleDownlaod = async (url: string) => {
    console.log(url)
    const fileName = url.substring(url.lastIndexOf('/') + 1).replace(/%20/g, ' ')

    try {
        const response = await fetch(url);
        const blob = await response.blob();
        console.log(blob)
        const downloadUrl = window.URL.createObjectURL(blob);

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName);  // Specify the file name dynamically

        // Append the anchor element to the body
        document.body.appendChild(link);

        // Create a click event
        const event = new MouseEvent('click');
        link.dispatchEvent(event);

        // Clean up: remove the anchor element and revoke the object URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};

export const useCases = ["Perquisite",
    "Salary Sacrifice",
    "Pool Cabs",
    "ETS",
    "Logistics",
    "Ride hailing",
    "Others"]
export default function OnboardingDetail({ prospectDetails, loading, getProspectDetails, setProspectDetails, allowedActionsForClient }: any) {
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [formPOC] = Form.useForm();
    const [buissnessSegement, setBusinessSegment] = useState([])
    const [addNewSegment, setAddNewSegment] = useState(false)
    const [docTypes, setDocTypes] = useState([])
    const [submitting, setSubmitting] = useState(false)
    const [delayPaymentType, setDelayPaymentType] = useState<string | null>(null)
    const [downPaymentType, setDownPaymentType] = useState<string | null>(null)
    const [securityDepositType, setSecurityDepositType] = useState<string | null>(null)
    const auth = useAppSelector(authSelector);

    const [showMonth, setShowMonths] = useState(false)
    const [showUseCaseName, setShowUseCaseName] = useState(false)
    const [showAddressModal, setShowAddressModal] = useState(false)
    const [fileList, setFileList] = useState<any[]>([]);
    const [cities, setCitites] = useState([])
    const [states, setStates] = useState([])
    const [fileUrls, setFileUrls] = useState<any>([])
    const [uploadType, setUploadType] = useState<any>({})
    const [editing, setEditing] = useState(false)
    const [showVersionHistory, setShowVersionHostory] = useState(false)
    const [updatingAddress, setUpdatingAddress] = useState<null | Number>(null)
    const [updatingPoc, setUpdatingPoc] = useState<null | Number>(null)
    const [viewAddress, setViewAddress] = useState({
        parentRegAddress: false,
        parentCommAddress: false,
        regAddress: false,
        commAddress: false,
        invoiceAddress: false
    })
    const [designations, setDesignations] = useState([])
    const [addingPoc, setAddingPoc] = useState(false)

    const [showAddPOC, setShowAddPOC] = useState<null | { isParent: boolean }>(null)
    const [othersToShow, setOthersToShow] = useState<boolean>(false)
    const [showCertificates, setShowCertificates] = useState(null)
    const [previewFile, setPreviewFile] = useState<null | {
        "fileName": string,
        "type": string,
        "url": string,
    }>(null)
    const [uploading, setUploading] = useState(false)
    const [ongoingUploads, setOngoingUploads] = useState(0);



    useEffect(() => {
        getBusinessSegment()
    }, [])

    useEffect(() => {
        getDesignations()
    }, [])

    // useEffect(() => {

    //     if (prospectDetails?.addressDetails) {
    //         const str = prospectDetails?.addressDetails?.find((i: any) => i.id == 106)?.url
    //         const urlsArray = JSON.parse('[' + prospectDetails?.addressDetails?.find((i: any) => i.id == 106)?.url.slice(1, -1) + ']').map((url:any) => url.trim());
    //         console.log("xxxxx", urlsArray)
    //     }
    // }, [prospectDetails])

    const getDesignations = () => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/designations`).then(res => {
            console.log("res.data", res.data)
            setDesignations(res.data);
        })
    };

    useEffect(() => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/cities`).then((res: any) => {
            console.log("cities", res.data)
            setCitites(res.data)
            getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/states`).then((res: any) => {
                console.log("states", res.data)
                setStates(res.data)
            });
        });
    }, [])

    const getBusinessSegment = () => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/segments`).then(res => {
            console.log("res.data", res.data)
            setBusinessSegment(res.data);
        })
    };



    const selectBefore = () => (
        <Select placeholder={editing ? "Select" : ""} onChange={(value) => { setDelayPaymentType(value); form.setFieldValue("delayPaymentChargesValue", null) }} style={{ width: 120 }} defaultValue={prospectDetails?.delayPaymentType}>
            <Select.Option value="%">%{!editing && " -"}</Select.Option>
            <Select.Option value="Fixed Value">Fixed Value{!editing && " -"}</Select.Option>

        </Select>
    );

    const downPaymentSelectBefore = () => (
        <Select placeholder={editing ? "Select" : ""} onChange={(value) => { setDownPaymentType(value); form.setFieldValue("downPaymentValue", null) }} style={{ width: 120 }} defaultValue={prospectDetails?.downPaymentType}>
            <Select.Option value="%">%{!editing && " -"}</Select.Option>
            <Select.Option value="Fixed Value">Fixed Value{!editing && " -"}</Select.Option>
        </Select>
    );

    const selectBeforeSecutiry = () => (
        <Select placeholder={editing ? "Select" : ""} onChange={(value) => { setSecurityDepositType(value); form.setFieldValue("securityDepositCharges", null) }} style={{ width: 100 }} defaultValue={prospectDetails?.securityDepositType}>
            <Select.Option value="LR">LR{!editing && " -"}</Select.Option>
            <Select.Option value="TIV">TIV{!editing && " -"}</Select.Option>
            <Select.Option value="Fixed Value">Fixed Value{!editing && " -"}</Select.Option>
        </Select>
    )

    const handleUploadRequest = async (file: any, type: string, key?: any) => {
        setUploading(true);
        setOngoingUploads((prev) => prev + 1);

        const url = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/uploadAgreementDoc?prospectId=${prospectDetails?.prospect?.prospectId}&isAddressDoc=true`;
        const formData = new FormData();
        formData.append('file', file.file);
        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Authorization': `Bearer ${auth.accessToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                console.log(response)
                console.log("response?.data", response?.data)

                if (response.status === 200) {
                    setFileUrls((prevState: any) => [...prevState, {
                        uid: file.file.uid,
                        url: response?.data?.DocumentUrl
                    }])
                    setFileList((prevState) => [...prevState, file.file]);

                }
                else {
                    console.log("error in uploading file")
                }
            })
        } catch (error) {
            console.error('Upload error:', error);
        } finally {
            setOngoingUploads((prev) => {
                const newOngoingUploads = prev - 1;
                if (newOngoingUploads === 0) {
                    // Disable loader when all uploads complete
                    setUploading(false);
                }
                return newOngoingUploads;
            });
        }
    };

    const handleOpenModal = (type: string, isParent: boolean) => {
        setShowAddressModal(true)
        setUploadType({
            type: type,
            isParent: isParent
        })
    }

    const handleDelete = (file: any) => {

        const tempFileList = [];
        const tempFileUrls = []
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i]?.uid !== file?.uid) {
                tempFileUrls.push(fileUrls[i])
                tempFileList.push(fileList[i]);
            }
        }
        setFileList(tempFileList);
        setFileUrls(tempFileUrls)
    }

    useEffect(() => {
        setFileList([])
        setFileUrls([])
    }, [showAddressModal])

    const addAddress = async () => {
        const values = form2.getFieldsValue()
        console.log("values", values)
        const newAddress = [{
            "address": values["new-address"].address,
            "pinCode": values["new-address"].pinCode,
            "addressType": uploadType.type,
            "allowDisable": true,
            "isParentAddress": uploadType.isParent,
            "entityGst": values["new-address"].entityGst,
            "cityId": values["new-address"].cityId,
            "stateId": values["new-address"].stateId,
            "url": fileUrls.map((i: any) => i.url)
        }]

        console.log("new address", newAddress)
        console.log("uploadType", uploadType)


        await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/addAddressDetails?clientOnBoardingId=${prospectDetails?.clientOnBoardingId}`, newAddress).then(res => {
            console.log(res)
            setShowAddressModal(false)
            getProspectDetails()
            form2.resetFields()
        }).catch(e => {
            console.log(e)
        })
    }

    const handleEnableAction = async (value: boolean, id: number) => {
        if (value) {
            setUpdatingAddress(id)
        } else {
            setUpdatingPoc(id)
        }
        setUpdatingAddress(id)
        console.log(value)
        await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/updateEnableAction/${id}?isAddress=${value}`, {}).then(res => {
            if (res.status == 200) {
                if (value) {
                    getProspectDetails()
                } else {
                    setProspectDetails({
                        ...prospectDetails,
                        prospect: {
                            ...prospectDetails.prospect,
                            spocList: prospectDetails.prospect.spocList.map((i: any) => {
                                return !(i.spocId == id) ? i : {
                                    ...i,
                                    allowDisable: !i.allowDisable
                                }
                            })
                        }
                    })
                }

            }
        }).catch((e) => console.log(e))
        if (value) {
            setUpdatingAddress(null)
        } else {
            setUpdatingPoc(null)
        }
    }

    const handleCancelEditing = () => {
        setEditing(false);
        setFormValues()
    }

    useEffect(() => {
        setFormValues()
    }, [prospectDetails])

    const setFormValues = () => {
        form.setFieldsValue({
            contractNumber: prospectDetails?.contractNumber,
            agreementType: prospectDetails?.agreementType,
            lockInPeriod: prospectDetails?.lockInPeriod,
            lockInPeriodMonths: prospectDetails?.lockInPeriodMonths,
            billingType: prospectDetails?.billingType,
            exitClause: prospectDetails?.exitClause,
            exitClauseDescription: prospectDetails?.exitClauseDescription,
            roadTax: prospectDetails?.roadTax,
            services: prospectDetails?.services,
            insurance: prospectDetails?.insurance,
            foreclosurePenalty: prospectDetails?.foreclosurePenalty,
            delayPaymentChargesValue: prospectDetails?.delayPaymentChargesValue,
            securityDepositCharges: prospectDetails?.securityDepositCharges,
            downPaymentValue: prospectDetails?.downPaymentValue,
            downPaymentType: prospectDetails?.downPaymentType,
            processingFeesValue: prospectDetails?.processingFeesValue,
            buyBack: prospectDetails?.buyBack,
            contractExecutionDate: prospectDetails?.contractExecutionDate && dayjs(prospectDetails?.contractExecutionDate),
            contractValidity: prospectDetails?.contractValidity && dayjs(prospectDetails?.contractValidity),
            delayPaymentType: prospectDetails?.delayPaymentType,
            securityDepositType: prospectDetails?.securityDepositType,
            primaryUseCase: (useCases.includes(prospectDetails?.primaryUseCase) || prospectDetails?.primaryUseCase==null) ? prospectDetails?.primaryUseCase : "Others",
            parentEntityName: prospectDetails?.prospect?.parentLegalBusinessName?.entityName,
            parentLegalBusinessName: prospectDetails?.prospect?.parentLegalBusinessName?.name,
            parentEntityPan: prospectDetails?.parentEntityPan,
            parentEntityCin: prospectDetails?.parentEntityCin,
            otherClause: prospectDetails?.otherClause,
            "segment": {
                "label": prospectDetails?.prospect?.segment?.name,
                "value": prospectDetails?.prospect?.segment?.id,
                "key": prospectDetails?.prospect?.segment?.id
            },
            "entityName": prospectDetails?.prospect?.legalBusinessName?.entityName,
            "legalBusinessName": prospectDetails?.prospect?.legalBusinessName?.name,
            "primaryContactNumber": prospectDetails?.primaryContactNumber,
            "primaryEmailId": prospectDetails?.primaryEmailId,
            "entityPan": prospectDetails?.entityPan,
            "entityCin": prospectDetails?.entityCin,
            otherPrimaryUseCase: useCases.includes(prospectDetails?.primaryUseCase) ? null : prospectDetails?.primaryUseCase,
            termOfContract: prospectDetails?.termOfContract
        })
        setDelayPaymentType(prospectDetails?.delayPaymentType)
        setDownPaymentType(prospectDetails?.downPaymentType)
        setSecurityDepositType(prospectDetails?.securityDepositType)
        setShowUseCaseName(!useCases.includes(prospectDetails?.primaryUseCase))
        setShowMonths(prospectDetails?.lockInPeriod == "Managed" ? true : false)
        setAddNewSegment(false)
    }


    const saveChanges = async () => {
        const formValues = form.getFieldsValue();
        console.log("formValues", formValues)
        console.log("date1", dayjs(formValues.contractExecutionDate).format("YYYY-MM-DD"))
        console.log("date1", dayjs(formValues.contractValidity).format("YYYY-MM-DD"))

        setSubmitting(true)
        const formData = {
            ...formValues,
            contractExecutionDate: formValues.contractExecutionDate ? dayjs(formValues.contractExecutionDate).format("YYYY-MM-DD") : null,
            contractValidity: formValues.contractValidity ? dayjs(formValues.contractValidity).format("YYYY-MM-DD") : null,
            segment: {
                "id": formValues.segment.label === "Other" ? null : formValues.segment.value,
                "name": formValues.segment.label === "Other" ? formValues.segmentName : formValues.segment.label,
            },
            isOtherSegment: formValues.segment.label === "Other",
            delayPaymentType: delayPaymentType,
            downPaymentType: downPaymentType,
            securityDepositType: securityDepositType,
            primaryUseCase: showUseCaseName ? formValues.otherPrimaryUseCase : formValues.primaryUseCase,
        }


        console.log(formData)

        // Conditionally remove properties
        if (prospectDetails?.prospect?.parentLegalBusinessName?.entityName === formData.parentEntityName) {
            delete formData.parentEntityName;
        }
        if (prospectDetails?.prospect?.parentLegalBusinessName?.name === formData.parentLegalBusinessName) {
            delete formData.parentLegalBusinessName;
        }
        if (prospectDetails?.prospect?.legalBusinessName?.entityName === formData.entityName) {
            delete formData.entityName;
        }
        if (prospectDetails?.prospect?.legalBusinessName?.name === formData.legalBusinessName) {
            delete formData.legalBusinessName;
        }
        delete formData.otherPrimaryUseCase;

        await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/updateDetails/${prospectDetails?.clientOnBoardingId}`, formData).then(res => {
            console.log(res)
            setSubmitting(false)
            if (res?.status == 200) {
                // message.success("Changes Saved")
                getProspectDetails();
                handleCancelEditing();
            }
        })
    }


    const handleCityIdSelect = async (cityId: any, name: any) => {
        const cityDetail: any = cities.find((city: any) => city.id == cityId);
        const gevlStateDetail: any = states.find((state: any) => state.stateCode == cityDetail.stateCode);
        if (gevlStateDetail) {
            form2.setFieldValue(name, gevlStateDetail.id)
        }
        else {
            form2.setFieldValue(name, HARYANA_STATE_ID)
        }
    }

    const handleAddPOC = async (values: any) => {
        setAddingPoc(true)
        console.log("values", values)
        let payload = {}
        payload = {

            "spocRequests": [{
                "name": values.name,
                "designation": values.designation.label === "Other" ? values.designationName : values.designation.label,
                "isOtherDesignation": values.designation.label === "Other",
                "contactNumber": values.contactNumber,
                "isAuthorisedSignatory": showAddPOC?.isParent,
                "emailId": values.emailId
            }],
            // clientStateStatus: formSteps[currentStep - 1].value,
            // creditLimit: prospectDetails?.creditLimit,
        }

        await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/updateDetails/${prospectDetails?.clientOnBoardingId}`, payload).then(res => {
            console.log(res)
            if (res?.status == 200) {
                getProspectDetails()
                setShowAddPOC(null)
                formPOC.resetFields()
            }
        }).catch((e) => console.log(e))
        setAddingPoc(false)

    }



    return <div className={editing ? "" : "disabled-form"}>
        <div className="view-state">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography.Text strong style={{ fontSize: 16 }}>Basic Details</Typography.Text>


                {!editing ?
                    <Space>
                        <Button onClick={() => setShowVersionHostory(true)} style={modalButtonStyle}>
                            <EyeOutlined /> Show Version History
                        </Button>
                        {allowedActionsForClient.has("editClientContractDetails") && <Button onClick={() => setEditing(true)} style={modalButtonStyle}>
                            <EditOutlined /> Edit
                        </Button>}
                    </Space> :

                    <Space>
                        <Button disabled={submitting} onClick={handleCancelEditing} danger style={{ ...modalButtonStyle, color: "red", borderColor: "red" }}>
                            <CloseOutlined /> Cancel
                        </Button>
                        <Button disabled={submitting} loading={submitting} onClick={saveChanges} style={modalButtonStyle}>
                            <CheckOutlined /> Save Changes
                        </Button>
                    </Space>}
            </div>

            <div style={{
                background: "white", padding: "20px 20px 0px 20px", borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", marginTop: 20
            }}>
                {!loading && <Form
                    // onFinish={() => handleSubmit()}
                    className="client-onboarding-form"
                    disabled={!editing}
                    form={form}
                    layout="vertical" name="basic-details">
                    <Row gutter={12}>
                        <Col span={8}>
                            <div>
                                <Typography.Text strong>Prospect</Typography.Text>
                            </div>
                            <div style={{ marginTop: 8 }}>
                                <Typography.Text>{prospectDetails?.prospect?.prospectId} - {prospectDetails?.prospect.name}</Typography.Text>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div>
                                <Typography.Text strong>Credit Limit</Typography.Text>
                            </div>
                            <div style={{ marginTop: 8 }}>
                                <Typography.Text>₹ {prospectDetails?.creditLimit}</Typography.Text>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={{ width: "100%", display: addNewSegment ? "flex" : "block", gap: 8 }}>
                                <Form.Item
                                    rules={[{ required: true, message: 'Please input Business Segment!' }]}
                                    name="segment" label="Business Segment">
                                    <Select labelInValue onChange={(value) => setAddNewSegment(
                                        value.label == "Other"
                                    )}>
                                        {buissnessSegement?.map((i: any) => {
                                            return <Select.Option value={i.id}>
                                                {i.name}
                                            </Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                {addNewSegment && <Form.Item
                                    rules={[{ required: true, message: 'Please input your Segment Name!' }]}
                                    style={{ flex: 1 }} name="segmentName" label=" ">
                                    <Input />
                                </Form.Item>}
                            </div>

                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[{ required: true, message: 'Please input Entity Name!' }]}
                                name="entityName" label="Entity Name">
                                <Input placeholder={!editing ? "" : "Enter Entity Name"} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[{ required: true, message: 'Please input Legal Business Name!' }]}
                                name="legalBusinessName" label="Legal Business Name">
                                <Input placeholder={!editing ? "" : "Enter Legal Business Name"} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[
                                    { required: true, message: 'Please input Primary Contact No.!' },
                                    { pattern: /^[0-9]*$/, message: 'Only numbers are allowed!' },
                                    { len: 10, message: 'Contact No. must be 10 digits!' }
                                ]}
                                name="primaryContactNumber" label="Primary Contact No.">
                                <Input maxLength={10} placeholder={!editing ? "" : "Enter Primary Contact No."} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[{ required: true, message: 'Please input Primary Email ID!' }, {
                                    type: 'email', message: 'Please input valid email!'
                                }]}
                                name="primaryEmailId" label="Primary Email ID">
                                <Input placeholder={!editing ? "" : "Enter Primary Email ID"} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[{ required: true, message: 'Please input PAN no.!' },
                                { len: 10, message: 'PAN No. must be 10 characters!' },
                                { pattern: /^[A-Z0-9]+$/, message: 'only alphabets and numbers allowed!' }
                                ]}
                                name="entityPan" label="Entity PAN no.">
                                <Input placeholder={!editing ? "" : "Enter Entity PAN no."} />
                            </Form.Item>
                        </Col>
                        {/* <Col span={8}>
                            <Form.Item
                        rules={[{ required: true, message: 'Please input your contact number!' }]}
                            label="Entity GST No.">
                                <Input placeholder={!editing ? "" : "Enter Entity GST No." name="entityGst"} />
                            </Form.Item>
                        </Col> */}
                        <Col span={8}>
                            <Form.Item
                                rules={[{ required: true, message: 'Please input CIN no.!' }]}
                                name="entityCin" label="Entity CIN no.">
                                <Input placeholder={!editing ? "" : "Enter Entity CIN no."} />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>}
            </div>
        </div>

        <div style={{ marginTop: 20 }} className="view-state">
            {!loading && <Form form={form}
                disabled={!editing} layout="vertical" name="client-details">
                <Typography.Text strong style={{ fontSize: 16 }}>Contract Details</Typography.Text>

                <div style={{
                    background: "white", padding: "20px 20px 0px 20px", borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", marginTop: 20
                }}>
                    <Row gutter={12}>
                        <Col span={8}>
                            <Form.Item
                                label="Contract No." name="contractNumber">
                                <Input placeholder={!editing ? "" : "Enter Contract No."} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label="Contract Execution Date" name="contractExecutionDate">
                                <DatePicker style={{ width: "100%" }} placeholder={!editing ? "" : "Enter Contract Execution Date"} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Contract Validity" name="contractValidity">
                                <DatePicker style={{ width: "100%" }} placeholder={!editing ? "" : "Enter Contract Validity"} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Term of Contract" name="termOfContract">
                                <Select placeholder={!editing ? "" : "Select Term of Contract"}>
                                    <Select.Option value="12">12</Select.Option>
                                    <Select.Option value="24">24</Select.Option>
                                    <Select.Option value="36">36</Select.Option>
                                    <Select.Option value="48">48</Select.Option>
                                    <Select.Option value="60">60</Select.Option>
                                    <Select.Option value="72">72</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Agreement Type" name="agreementType">
                                <Select placeholder={!editing ? "" : "Select Agreement Type"}>
                                    <Select.Option value="leasing">Leasing</Select.Option>
                                    <Select.Option value="services (management only)">Services (Management only)</Select.Option>

                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label="Billing Frequency" name="billingType">
                                <Select placeholder={!editing ? "" : "Select Billing Frequency"}>
                                    <Select.Option value="Monthly">Monthly</Select.Option>
                                    <Select.Option value="Quarterly">Quarterly</Select.Option>
                                    <Select.Option value="Half Yearly">Half Yearly</Select.Option>
                                    <Select.Option value="Yearly">Yearly</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <div style={{ display: "flex", gap: 10 }}>
                                <div style={{ flex: editing ? 1 : "none" }}>
                                    <Form.Item label="Primary Use Case" name="primaryUseCase">
                                        <Select onSelect={(value) => value == "Others" ? setShowUseCaseName(true) : setShowUseCaseName(false)} placeholder={!editing ? "" : "Select Primary Use Case"}>
                                            <Select.Option value="Perquisite">Perquisite</Select.Option>
                                            <Select.Option value="Salary Sacrifice">Salary Sacrifice</Select.Option>
                                            <Select.Option value="Pool Cabs">Pool Cabs</Select.Option>
                                            <Select.Option value="ETS">ETS</Select.Option>
                                            <Select.Option value="Logistics">Logistics</Select.Option>
                                            <Select.Option value="Ride hailing">Ride hailing</Select.Option>
                                            <Select.Option value="Others">Others</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                {showUseCaseName && <div style={{ marginLeft: editing ? 0 : "-65px" }}>
                                    {!editing ?  <Form.Item  label=" ">{prospectDetails?.primaryUseCase ? prospectDetails?.primaryUseCase : ""}</Form.Item> : <Form.Item label=" " name="otherPrimaryUseCase">
                                        <Input placeholder={!editing ? "" : "Enter Primary Use Case"} />
                                    </Form.Item>}
                                </div>}

                            </div>

                        </Col>
                        <Col span={8}>
                            <Form.Item label="Exit Clause" name="exitClause">
                                <Select placeholder={!editing ? "" : "Select Exit Clause"}>
                                    <Select.Option value="OBV">OBV</Select.Option>
                                    <Select.Option value="Walkaway">Walkaway</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Lock In Period" name="lockInPeriod">
                                <Select placeholder={!editing ? "" : "Select Lock In Period"} onChange={(value) => { form.setFieldValue("lockInPeriodMonths", null); setShowMonths(value == "Managed" ? true : false) }}>
                                    <Select.Option value="Managed">Applicable</Select.Option>
                                    <Select.Option value="Not Managed">Not Applicable</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            {showMonth && <Form.Item
                                rules={[
                                    { pattern: /^[0-9]*$/, message: 'Only numbers are allowed!' },

                                ]}
                                label="Lock In Period Months" name="lockInPeriodMonths">
                                <InputNumber style={{ width: "100%" }} placeholder={!editing ? "" : "Select Lock In Period Months"} />
                            </Form.Item>}
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Exit Clause Description" name="exitClauseDescription">
                                <Input.TextArea rows={2} placeholder={!editing ? "" : "Select Exit Clause Description"} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div>
                        <Typography.Text strong>Contracted Services</Typography.Text>
                        <Divider style={{ marginTop: 4, marginBottom: 10 }} />
                    </div>
                    <div>
                        <Row gutter={12}>
                            <Col span={8}>
                                <Form.Item label="Road Tax" name="roadTax">
                                    <Select placeholder={!editing ? "" : "Select Road Tax"}>
                                        <Select.Option value="Managed">Managed</Select.Option>
                                        <Select.Option value="Not Managed">Not Managed</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Services" name="services">
                                    <Select placeholder={!editing ? "" : "Select Service"}>
                                        <Select.Option value="Managed">Managed</Select.Option>
                                        <Select.Option value="Not Managed">Not Managed</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Insurance" name="insurance">
                                    <Select placeholder={!editing ? "" : "Select Insurance"}>
                                        <Select.Option value="Managed">Managed</Select.Option>
                                        <Select.Option value="Not Managed">Not Managed</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                    </div>

                    <div>
                        <Typography.Text strong>Charges & Penalties</Typography.Text>
                        <Divider style={{ marginTop: 4, marginBottom: 10 }} />
                    </div>
                    <div>
                        <Row gutter={12}>

                            <Col span={8}>
                                <Form.Item
                                    rules={[
                                        {
                                            pattern: /^(100(\.0{1,2})?|[0-9]{1,2}(\.[0-9]{1,2})?)$/,
                                            message: 'Must be a positive number less than 100 with up to two decimal points!',
                                        }
                                    ]}
                                    label="Foreclosure Penalty %" name="foreclosurePenalty">
                                    <Input placeholder={!editing ? "" : "Enter Foreclosure Penalty %"} />
                                </Form.Item>
                            </Col>
                            {prospectDetails && <Col span={8}>
                                <Form.Item
                                    rules={[
                                        { pattern: delayPaymentType !== "%" ? /^\d*\.?\d*$/ : /^(100(\.0{1,2})?|[0-9]{1,2}(\.[0-9]{1,2})?)$/, message: delayPaymentType !== "%" ? 'Only numbers are allowed!' : 'Must be a positive number less than 100 with up to two decimal points!' }
                                    ]}
                                    label="Delay Payment Charges" name="delayPaymentChargesValue">
                                    <Input placeholder={!editing ? "" : "Enter Delay Payment Charges"} addonBefore={selectBefore()} />
                                </Form.Item>
                            </Col>}


                            {prospectDetails && <Col span={8}>
                                <Form.Item
                                    rules={[
                                        { pattern: securityDepositType !== "%" ? /^\d*\.?\d*$/ : /^(100(\.0{1,2})?|[0-9]{1,2}(\.[0-9]{1,2})?)$/, message: securityDepositType !== "%" ? 'Only numbers are allowed!' : 'Must be a positive number less than 100 with up to two decimal points!' }
                                    ]}
                                    label="Security Deposit Value" name="securityDepositCharges">
                                    <Input placeholder={!editing ? "" : "Enter Security Deposit Value"} addonBefore={selectBeforeSecutiry()} />
                                </Form.Item>
                            </Col>}
                            {prospectDetails && <Col span={8}>
                                <Form.Item
                                    rules={[
                                        { pattern: downPaymentType !== "%" ? /^\d*\.?\d*$/ : /^(100(\.0{1,2})?|[0-9]{1,2}(\.[0-9]{1,2})?)$/, message: downPaymentType !== "%" ? 'Only numbers are allowed!' : 'Must be a positive number less than 100 with up to two decimal points!' }
                                    ]}
                                    label="Down Payment Value" name="downPaymentValue">
                                    <Input placeholder={!editing ? "" : "Down Payment Value (%)"} addonBefore={downPaymentSelectBefore()} />
                                </Form.Item>
                            </Col>}
                            <Col span={8}>
                                <Form.Item label="Processing Fees Value" name="processingFeesValue">
                                    <Input placeholder={!editing ? "" : "Enter Processing Fees Value"} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Buy Back" name="buyBack">
                                    <Select placeholder={!editing ? "" : "Select Buy Back"}>
                                        <Select.Option value="Applicable">Applicable</Select.Option>
                                        <Select.Option value="Not Applicable">Not Applicable</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>

                    </div>

                </div>







            </Form>}

        </div>

        <div style={{ marginTop: 20 }} className="view-state">

            <Typography.Text strong style={{ fontSize: 16 }}>Parent Details </Typography.Text>

            <div style={{
                background: "white", padding: "20px 20px 20px 20px", borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", marginTop: 20
            }}>
                <div>
                    <Typography.Text strong>Basic Details</Typography.Text>
                    <Divider style={{ marginTop: 4, marginBottom: 10 }} />
                </div>
                {!loading && <Form disabled={!editing} form={form} layout="vertical" name="client-details">
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item label="Parent Entity Name" name="parentEntityName">
                                <Input placeholder={!editing ? "" : "Enter Parent Entity Name"} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Parent Legal Business Name" name="parentLegalBusinessName">
                                <Input placeholder={!editing ? "" : "Enter Parent Legal Business Name"} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item rules={[
                                { len: 10, message: 'PAN No. must be 10 characters!' },
                                { pattern: /^[A-Z0-9]+$/, message: 'only alphabets and numbers allowed!' }
                            ]} label="Entity PAN no." name="parentEntityPan">
                                <Input placeholder={!editing ? "" : "Enter Entity PAN no."} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={<>Entity CIN no.</>} name="parentEntityCin">
                                <Input placeholder={!editing ? "" : "Enter Entity CIN no."} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>}
                <div>
                    <Typography.Text strong>Addresses</Typography.Text>
                    <Divider style={{ marginTop: 4 }} />
                </div>
                <div style={{ background: "#E6F2FD", borderRadius: 12, }}>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "12px 20px" }}>
                        <div>
                            <Typography.Text>Registered Address   &nbsp;({prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Registration Address" && i.isParentAddress).length})</Typography.Text>
                        </div>
                        <div onClick={() => setViewAddress({
                            ...viewAddress,
                            parentRegAddress: !viewAddress.parentRegAddress
                        })} style={{ display: "flex", gap: 4, color: "#1777FF", cursor: "pointer" }}>
                            <div>
                                {viewAddress.parentRegAddress ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                            </div>
                            <Typography.Text style={{ color: "#1777FF" }}>
                                {viewAddress.parentRegAddress ? "Hide" : "View"} Details
                            </Typography.Text>
                        </div>
                    </div>
                    {viewAddress.parentRegAddress && <>
                        <Divider style={{ marginTop: 0, marginBottom: 8 }} />
                        <div style={{ paddingInline: 20, paddingBottom: 12 }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span style={{ fontSize: 12, fontWeight: "bold" }}>{prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Registration Address" && i.isParentAddress).length} Addresses</span>
                                {/* <Button onClick={() => handleOpenModal("Registration Address", true)} style={{ color: "#1777FF" }} size="small" type="text"><PlusOutlined /> Add New Address</Button> */}
                            </div>

                            {prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Registration Address" && i.isParentAddress).map((address: any, key: number) => {
                                return <div style={{ display: "flex", justifyContent: "space-between", marginTop: 16 }}>
                                    <div style={{ width: "70%" }}>
                                        <Typography.Text style={{ display: "block", color: !address.allowDisable ? "grey" : "black" }} strong>
                                            {key + 1}. {address.address}, {address.city.cityName}, {address.state.stateName}, {address.pinCode}
                                        </Typography.Text>
                                        <Typography.Text style={{ display: "block", color: !address.allowDisable ? "grey" : "black" }}>
                                            Updated at: {moment(address.updatedAt).format('h:mm A, D MMM YYYY')} | Updated by: {JSON.parse(address.updatedBy)?.name}
                                        </Typography.Text>
                                    </div>
                                    <Space>
                                        <Space onClick={() => setShowCertificates(address.id)} style={{ cursor: "pointer" }}>
                                            <img width={9} src={`${__dirname}assets/document.png`} />
                                            <Typography style={{ color: "rgb(23, 119, 255" }}>View Certificates</Typography>
                                        </Space>
                                        
                                        {allowedActionsForClient.has("updateAddressSpoc") && <>
                                        <Divider type="vertical" />
                                        <Switch loading={updatingAddress == address.id} size="small" checked={address.allowDisable} onChange={(checked) => handleEnableAction(true, address.id)} />
                                        </>}
                                    </Space>

                                </div>
                            })}
                        </div>
                    </>}

                </div>

                <div style={{ background: "#E6F2FD", borderRadius: 12, marginTop: 20 }}>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "12px 20px" }}>
                        <div>
                            <Typography.Text>Communication Address &nbsp;({prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Communication Address" && i.isParentAddress).length})</Typography.Text>
                        </div>
                        <div onClick={() => setViewAddress({
                            ...viewAddress,
                            parentCommAddress: !viewAddress.parentCommAddress
                        })} style={{ display: "flex", gap: 4, color: "#1777FF", cursor: "pointer" }}>
                            <div>
                                {viewAddress.parentCommAddress ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                            </div>
                            <Typography.Text style={{ color: "#1777FF" }}>
                                {viewAddress.parentCommAddress ? "Hide" : "View"} Details
                            </Typography.Text>
                        </div>
                    </div>
                    {viewAddress.parentCommAddress && <>
                        <Divider style={{ marginTop: 0, marginBottom: 8 }} />
                        <div style={{ paddingInline: 20, paddingBottom: 12 }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span style={{ fontSize: 12, fontWeight: "bold" }}>{prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Communication Address" && i.isParentAddress).length} Addresses</span>
                                {allowedActionsForClient.has("addAddressSpoc") && <Button onClick={() => handleOpenModal("Communication Address", true)} style={{ color: "#1777FF" }} size="small" type="text"><PlusOutlined /> Add New Address</Button>}
                            </div>

                            {prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Communication Address" && i.isParentAddress).map((address: any, key: number) => {
                                return <div style={{ display: "flex", justifyContent: "space-between", marginTop: 16 }}>
                                    <div style={{ width: "70%" }}>
                                        <Typography.Text style={{ display: "block", color: !address.allowDisable ? "grey" : "black" }} strong>
                                            {key + 1}. {address.address}, {address.city.cityName}, {address.state.stateName}, {address.pinCode}
                                        </Typography.Text>
                                        <Typography.Text style={{ display: "block", color: !address.allowDisable ? "grey" : "black" }}>
                                            Updated at: {moment(address.updatedAt).format('h:mm A, D MMM YYYY')} | Updated by: {JSON.parse(address.updatedBy)?.name}
                                        </Typography.Text>
                                    </div>
                                    <Space>
                                        <Space onClick={() => setShowCertificates(address.id)} style={{ cursor: "pointer" }}>
                                            <img width={9} src={`${__dirname}assets/document.png`} />
                                            <Typography style={{ color: "rgb(23, 119, 255" }}>View Certificates</Typography>
                                        </Space>
                                        
                                        {allowedActionsForClient.has("updateAddressSpoc") && 
                                        <>
                                        <Divider type="vertical" />
                                        {allowedActionsForClient.has("updateAddressSpoc") && <Switch loading={updatingAddress == address.id} size="small" checked={address.allowDisable} onChange={(checked) => handleEnableAction(true, address.id)} />}
                                        </>}
                                    </Space>

                                </div>
                            })}
                        </div>
                    </>}


                </div>

            </div>

        </div>

        <div style={{ marginTop: 20 }} className="view-state">

            <Typography.Text strong style={{ fontSize: 16 }}>Addresses</Typography.Text>

            <div style={{
                background: "white", padding: "20px 20px 20px 20px", borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", marginTop: 20
            }}>
                <div style={{ background: "#E6F2FD", borderRadius: 12, }}>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "12px 20px" }}>
                        <div>
                            <Typography.Text>Registered Address &nbsp; ({prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Registration Address" && !i.isParentAddress).length})</Typography.Text>
                        </div>
                        <div onClick={() => setViewAddress({
                            ...viewAddress,
                            regAddress: !viewAddress.regAddress
                        })} style={{ display: "flex", gap: 4, color: "#1777FF", cursor: "pointer" }}>
                            <div>
                                {viewAddress.regAddress ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                            </div>
                            <Typography.Text style={{ color: "#1777FF" }}>
                                {viewAddress.regAddress ? "Hide" : "View"} Details
                            </Typography.Text>
                        </div>
                    </div>
                    {viewAddress.regAddress && <>
                        <Divider style={{ marginTop: 0, marginBottom: 8 }} />
                        <div style={{ paddingInline: 20, paddingBottom: 12 }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span style={{ fontSize: 12, fontWeight: "bold" }}>{prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Registration Address" && !i.isParentAddress).length} Addresses</span>
                                {/* <Button onClick={() => handleOpenModal("Registration Address", false)} style={{ color: "#1777FF" }} size="small" type="text"><PlusOutlined /> Add New Address</Button> */}
                            </div>

                            {prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Registration Address" && !i.isParentAddress).map((address: any, key: number) => {
                                return <div style={{ display: "flex", justifyContent: "space-between", marginTop: 16 }}>
                                    <div style={{ width: "70%" }}>
                                        <Typography.Text style={{ display: "block", color: !address.allowDisable ? "grey" : "black" }} strong>
                                            {key + 1}. {address.address}, {address.city.cityName}, {address.state.stateName}, {address.pinCode}
                                        </Typography.Text>
                                        <Typography.Text style={{ display: "block", color: !address.allowDisable ? "grey" : "black" }}>
                                            Updated at: {moment(address.updatedAt).format('h:mm A, D MMM YYYY')} | Updated by: {JSON.parse(address.updatedBy)?.name}
                                        </Typography.Text>
                                    </div>
                                    <Space>
                                        <Space onClick={() => setShowCertificates(address.id)} style={{ cursor: "pointer" }}>
                                            <img width={9} src={`${__dirname}assets/document.png`} />
                                            <Typography style={{ color: "rgb(23, 119, 255" }}>View Certificates</Typography>
                                        </Space>
                                        {allowedActionsForClient.has("updateAddressSpoc") && <>
                                            <Divider type="vertical" />
                                            <Switch loading={updatingAddress == address.id} size="small" checked={address.allowDisable} onChange={(checked) => handleEnableAction(true, address.id)} />
                                        </>}
                                    </Space>

                                </div>
                            })}
                        </div>
                    </>}


                </div>

                <div style={{ background: "#E6F2FD", borderRadius: 12, marginTop: 20 }}>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "12px 20px" }}>
                        <div>
                            <Typography.Text>Communication Address &nbsp; ({prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Communication Address" && !i.isParentAddress).length})</Typography.Text>
                        </div>
                        <div onClick={() => setViewAddress({
                            ...viewAddress,
                            commAddress: !viewAddress.commAddress
                        })} style={{ display: "flex", gap: 4, color: "#1777FF", cursor: "pointer" }}>
                            <div>
                                {viewAddress.commAddress ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                            </div>
                            <Typography.Text style={{ color: "#1777FF" }}>
                                {viewAddress.commAddress ? "Hide" : "View"} Details
                            </Typography.Text>
                        </div>
                    </div>

                    {viewAddress.commAddress && <>
                        <Divider style={{ marginTop: 0, marginBottom: 8 }} />
                        <div style={{ paddingInline: 20, paddingBottom: 12 }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span style={{ fontSize: 12, fontWeight: "bold" }}>{prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Communication Address" && !i.isParentAddress).length} Addresses</span>
                                {allowedActionsForClient.has("addAddressSpoc") && <Button onClick={() => handleOpenModal("Communication Address", false)} style={{ color: "#1777FF" }} size="small" type="text"><PlusOutlined /> Add New Address</Button>}
                            </div>

                            {prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Communication Address" && !i.isParentAddress).map((address: any, key: number) => {
                                return <div style={{ display: "flex", justifyContent: "space-between", marginTop: 16 }}>
                                    <div style={{ width: "70%" }}>
                                        <Typography.Text style={{ display: "block", color: !address.allowDisable ? "grey" : "black" }} strong>
                                            {key + 1}. {address.address}, {address.city.cityName}, {address.state.stateName}, {address.pinCode}
                                        </Typography.Text>
                                        <Typography.Text style={{ display: "block", color: !address.allowDisable ? "grey" : "black" }}>
                                            Updated at: {moment(address.updatedAt).format('h:mm A, D MMM YYYY')} | Updated by: {JSON.parse(address.updatedBy)?.name}
                                        </Typography.Text>
                                    </div>
                                    <Space>
                                        <Space onClick={() => setShowCertificates(address.id)} style={{ cursor: "pointer" }}>
                                            <img width={9} src={`${__dirname}assets/document.png`} />
                                            <Typography style={{ color: "rgb(23, 119, 255" }}>View Certificates</Typography>
                                        </Space>
                                        {allowedActionsForClient.has("updateAddressSpoc") && <>
                                            <Divider type="vertical" />
                                            <Switch loading={updatingAddress == address.id} size="small" checked={address.allowDisable} onChange={(checked) => handleEnableAction(true, address.id)} />
                                        </>}
                                    </Space>

                                </div>
                            })}
                        </div>
                    </>}


                </div>

                <div style={{ background: "#E6F2FD", borderRadius: 12, marginTop: 20 }}>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "12px 20px" }}>
                        <div>
                            <Typography.Text>Invoice Address &nbsp; ({prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Invoice Address" && !i.isParentAddress).length})</Typography.Text>
                        </div>
                        <div onClick={() => setViewAddress({
                            ...viewAddress,
                            invoiceAddress: !viewAddress.invoiceAddress
                        })} style={{ display: "flex", gap: 4, color: "#1777FF", cursor: "pointer" }}>
                            <div>
                                {viewAddress.invoiceAddress ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                            </div>
                            <Typography.Text style={{ color: "#1777FF" }}>
                                {viewAddress.invoiceAddress ? "Hide" : "View"} Details
                            </Typography.Text>
                        </div>
                    </div>

                    {viewAddress.invoiceAddress && <>
                        <Divider style={{ marginTop: 0, marginBottom: 8 }} />
                        <div style={{ paddingInline: 20, paddingBottom: 12 }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span style={{ fontSize: 12, fontWeight: "bold" }}>{prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Invoice Address" && !i.isParentAddress).length} Addresses</span>
                                {allowedActionsForClient.has("addAddressSpoc") && <Button onClick={() => handleOpenModal("Invoice Address", false)} style={{ color: "#1777FF" }} size="small" type="text"><PlusOutlined /> Add New Address</Button>}
                            </div>

                            {prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Invoice Address" && !i.isParentAddress).map((address: any, key: number) => {
                                return <div style={{ display: "flex", justifyContent: "space-between", marginTop: 16 }}>
                                    <div style={{ width: "70%" }}>
                                        <Typography.Text style={{ display: "block", color: !address.allowDisable ? "grey" : "black" }} strong>
                                            {key + 1}. {address.address}, {address.city.cityName}, {address.state.stateName}, {address.pinCode}
                                        </Typography.Text>
                                        <Typography.Text style={{ display: "block", color: !address.allowDisable ? "grey" : "black" }}>
                                            Updated at: {moment(address.updatedAt).format('h:mm A, D MMM YYYY')} | Updated by: {JSON.parse(address.updatedBy)?.name}
                                        </Typography.Text>
                                    </div>
                                    <Space>
                                        <Space onClick={() => setShowCertificates(address.id)} style={{ cursor: "pointer" }}>
                                            <img width={9} src={`${__dirname}assets/document.png`} />
                                            <Typography style={{ color: "rgb(23, 119, 255" }}>View Certificates</Typography>
                                        </Space>
                                        
                                        {allowedActionsForClient.has("updateAddressSpoc") && <>
                                            <Divider type="vertical" />
                                            <Switch loading={updatingAddress == address.id} size="small" checked={address.allowDisable} onChange={(checked) => handleEnableAction(true, address.id)} />
                                        </>}
                                    </Space>

                                </div>
                            })}
                        </div>
                    </>}

                </div>
            </div>
        </div>

        <div style={{ marginTop: 20 }} className="view-state">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography.Text strong style={{ fontSize: 16 }}>SPOC Details</Typography.Text>
                {allowedActionsForClient.has("addAddressSpoc") && <Button onClick={() => setShowAddPOC({ isParent: false })} style={modalButtonStyle}>
                    <PlusOutlined /> Add New SPOC
                </Button>}
            </div>

            <div style={{
                background: "white", padding: "20px 20px 20px 20px", borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", marginTop: 20
            }}>

                <Row style={{ padding: "12px 20px", borderRadius: 12 }}>
                    <Col span={5}>
                        <Typography.Text strong>Name</Typography.Text>
                    </Col>
                    <Col span={5}>
                        <Typography.Text strong>Designation</Typography.Text>
                    </Col>
                    <Col span={5}>
                        <Typography.Text strong>Contact Number</Typography.Text>
                    </Col>
                    <Col span={5}>
                        <Typography.Text strong>Email</Typography.Text>
                    </Col>
                    {allowedActionsForClient.has("updateAddressSpoc") && <Col span={4}>
                        <Typography.Text strong>Action</Typography.Text>
                    </Col>}
                </Row>
                {prospectDetails?.prospect?.spocList?.filter((i: any) => !i.isAuthorisedSignatory && i.active).map((item: any) => {
                    return <Row style={{ background: "#E6F2FD", padding: "12px 20px", borderRadius: 12, marginTop: 8 }}>
                        <Col span={5}>
                            <Typography.Text style={{ color: item.allowDisable ? "black" : "grey" }}>{item.name}</Typography.Text>
                        </Col>
                        <Col span={5}>
                            <Typography.Text style={{ color: item.allowDisable ? "black" : "grey" }}>{item.designation}</Typography.Text>
                        </Col>
                        <Col span={5}>
                            <Typography.Text style={{ color: item.allowDisable ? "black" : "grey" }}>{item.contactNumber}</Typography.Text>
                        </Col>
                        <Col span={5}>
                            <Typography.Text style={{ color: item.allowDisable ? "black" : "grey" }}>{item.emailId}</Typography.Text>
                        </Col>
                        <Col span={4}>
                            <Typography.Text style={{ color: item.allowDisable ? "black" : "grey" }}>
                                {allowedActionsForClient.has("updateAddressSpoc") && <Switch loading={updatingPoc == item.spocId} size="small" checked={item.allowDisable} onChange={(checked) => handleEnableAction(false, item.spocId)} />}
                            </Typography.Text>
                        </Col>
                    </Row>
                })}


            </div>
        </div>

        <div style={{ marginTop: 20 }} className="view-state">

            <Typography.Text strong style={{ fontSize: 16 }}>Agreement Details</Typography.Text>

            <div style={{
                background: "white", padding: "20px 20px 20px 20px", borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", marginTop: 20
            }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography.Text strong>Authorized Signatories</Typography.Text>

                    {allowedActionsForClient.has("addAddressSpoc") && <Button onClick={() => setShowAddPOC({ isParent: true })} style={modalButtonStyle}>
                        <PlusOutlined /> Add New Signatory
                    </Button>}
                </div>
                <Row style={{ padding: "12px 20px", borderRadius: 12 }}>
                    <Col span={5}>
                        <Typography.Text strong>Name</Typography.Text>
                    </Col>
                    <Col span={5}>
                        <Typography.Text strong>Designation</Typography.Text>
                    </Col>
                    <Col span={5}>
                        <Typography.Text strong>Contact Number</Typography.Text>
                    </Col>
                    <Col span={5}>
                        <Typography.Text strong>Email</Typography.Text>
                    </Col>
                    {allowedActionsForClient.has("updateAddressSpoc") && <Col span={4}>
                        <Typography.Text strong>Action</Typography.Text>
                    </Col>}
                </Row>
                {prospectDetails?.prospect?.spocList?.filter((i: any) => i.isAuthorisedSignatory).map((item: any) => {
                    return <Row style={{ background: "#E6F2FD", padding: "12px 20px", borderRadius: 12, marginTop: 8 }}>
                        <Col span={5}>
                            <Typography.Text style={{ color: item.allowDisable ? "black" : "grey" }}>{item.name}</Typography.Text>
                        </Col>
                        <Col span={5}>
                            <Typography.Text style={{ color: item.allowDisable ? "black" : "grey" }}>{item.designation}</Typography.Text>
                        </Col>
                        <Col span={5}>
                            <Typography.Text style={{ color: item.allowDisable ? "black" : "grey" }}>{item.contactNumber}</Typography.Text>
                        </Col>
                        <Col span={5}>
                            <Typography.Text style={{ color: item.allowDisable ? "black" : "grey" }}>{item.emailId}</Typography.Text>
                        </Col>
                        <Col span={4}>
                            <Typography.Text style={{ color: item.allowDisable ? "black" : "grey" }}>
                                {allowedActionsForClient.has("updateAddressSpoc") && <Switch loading={updatingPoc == item.spocId} size="small" checked={item.allowDisable} onChange={(checked) => handleEnableAction(false, item.spocId)} />}
                            </Typography.Text>
                        </Col>
                    </Row>
                })}
                <div style={{ marginTop: 20 }}>
                    <Typography.Text strong>Other Clauses</Typography.Text>
                    <Divider style={{ marginTop: 4 }} />
                </div>
                <div>
                    {!loading && <Form form={form} disabled={!editing} >
                        <Form.Item name="otherClause">
                            <Input.TextArea rows={6} />
                        </Form.Item>
                    </Form>}

                </div>

            </div>
        </div>

        <Modal
            centered style={{ textAlign: "center" }}
            footer={null}
            onCancel={() => {
                setShowAddressModal(false)
                form2.resetFields()
            }}
            title={`Add New ${uploadType.type}`}
            closable={true}
            open={showAddressModal}
            styles={{
                content: {
                    background: "#F6FAFF",
                },
                body: {
                    background: "#F6FAFF",
                    margin: 0,
                    padding: 0
                },
                header: {
                    background: "#F6FAFF",
                    textAlign: "left"
                }
            }}
            width={1000}
            destroyOnClose
        >
            <Form form={form2} onFinish={addAddress} layout="vertical" name="add-address" style={{ marginTop: 20, textAlign: "left" }}>

                <Row gutter={12}>
                    <Col span={16}>
                        <Form.Item
                            label="Address" rules={[{ required: true, message: 'Please enter address!' }]} name={["new-address", "address"]}>
                            <Input.TextArea rows={5} placeholder="Enter Address" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label="GST No." name={["new-address", "entityGst"]}>
                                    <Input placeholder="Enter GST No" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Upload Certificate">
                                    <Upload
                                        accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
                                        style={{ width: "100%", display: "block" }}
                                        customRequest={(file) => handleUploadRequest({ file }, "Registration Address")}
                                        beforeUpload={(file) => {
                                            handleUploadRequest({ file }, "Registration Address")
                                            return false
                                        }}
                                        fileList={fileList}
                                        onRemove={handleDelete}
                                        itemRender={(originNode, file, fileList2, actions) => <div style={{ display: "flex", justifyContent: "space-between", marginTop: 8 }}>
                                            <div style={{ width: 150 }}>
                                                <Typography.Text ellipsis>
                                                    <Tooltip title={file.name}>
                                                        {file.name}
                                                    </Tooltip>
                                                </Typography.Text>
                                            </div>
                                            <div style={{ display: "flex", gap: 10 }}>
                                                {["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(file.type as string) ? <div onClick={() => {
                                                    const itemIndex = fileList2.findIndex((i) => i.uid === file.uid);
                                                    console.log("file.uid", file.uid);
                                                    console.log("fileUrls", fileUrls)
                                                    setPreviewFile({
                                                        "fileName": file.name,
                                                        "type": file.type as string,
                                                        "url": fileUrls.find((i: any) => i.uid == file.uid).url,
                                                    })
                                                }} style={{ cursor: "pointer" }}>
                                                    <EyeOutlined />
                                                </div> :
                                                    <EyeInvisibleFilled style={{ color: "grey" }} />
                                                }

                                                <Button disabled={uploading} onClick={actions.remove} size="small" type="text" style={{ cursor: "pointer" }}>
                                                    <DeleteFilled style={{ color: "red" }} />
                                                </Button>
                                            </div>


                                        </div>}
                                        listType="text"
                                        multiple={true}
                                    >

                                        <Button
                                            type="default"
                                            //  disabled={fileList.length > 0}
                                            style={{ width: "100%", display: "block", border: "1px solid rgba(44, 102, 227, 1)", color: "rgba(44, 102, 227, 1)" }}
                                            icon={uploading ? <Spin indicator={antIconUpload} /> : <UploadOutlined />}
                                        >Upload File</Button>
                                    </Upload>

                                </Form.Item>
                            </Col>
                        </Row>

                    </Col>
                    <Col span={8}>
                        <Form.Item

                            rules={[
                                { required: true, message: 'Please enter pincode!' },
                                { pattern: /^[0-9]*$/, message: 'Only numbers are allowed!' }
                            ]}
                            label="Pincode" name={["new-address", "pinCode"]}>
                            <Input placeholder="Enter Pincode" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item style={{ width: '100%', marginRight: '20px', textAlign: "left" }}
                            label="City"
                            name={["new-address", "cityId"]}
                            rules={[{ required: true, message: 'Please enter City!' }]}
                        >
                            <SelectSearch name={["new-address", "cityId"]} placeholder={"City"} options={cities?.map((id: any) => {
                                return {
                                    id: id?.id,
                                    name: id?.cityName,
                                }
                            })
                            } onSelect={(value) => handleCityIdSelect(value, ["new-address", "stateId"])} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item style={{ width: '100%', marginRight: '20px', textAlign: "left" }}
                            label="State"
                            name={["new-address", "stateId"]}
                            rules={[{ required: true, message: 'Please enter state!' }]}
                        >
                            <SelectSearch name={["new-address", "stateId"]} placeholder={"State"} options={Array.isArray(states) ? states.map((id: any) => {
                                return {
                                    id: id?.id,
                                    name: id?.stateName,
                                }
                            }) : []
                            } />
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ textAlign: "right", marginTop: 28 }}>
                    <Form.Item>
                        <Button disabled={uploading} htmlType="submit" size="large" type="primary">Add</Button>
                    </Form.Item>
                </div>
            </Form>

        </Modal>

        <Modal width={1200} footer={null}
            centered style={{ textAlign: "center" }}
            styles={{
                content: {
                    background: "#F6FAFF",
                },
                body: {
                    background: "#F6FAFF",
                    margin: 0,
                    padding: 0
                }
            }}
            onCancel={() => setShowVersionHostory(false)}
            closable={true}
            destroyOnClose
            open={showVersionHistory}

        >
            <VersionHistory prospectDetails={prospectDetails} showVersionHistory={showVersionHistory} setShowVersionHostory={setShowVersionHostory} clientOnBoardingId={prospectDetails?.clientOnBoardingId} />
        </Modal>

        <Modal
            centered style={{ textAlign: "center" }}
            footer={null}
            onCancel={() => {
                setShowAddPOC(null)
                formPOC.resetFields()
            }}
            title={showAddPOC?.isParent ? "Add New Signatory" : "Add New SPOC"}
            closable={true}
            open={showAddPOC ? true : false}
            styles={{
                content: {
                    background: "#F6FAFF",
                    textAlign: "left"
                },
                body: {
                    background: "#F6FAFF",
                    margin: 0,
                    padding: 0
                },
                header: {
                    background: "#F6FAFF",
                    textAlign: "left"
                }
            }}
            width={600}
            destroyOnClose
        >
            <Form onFinish={handleAddPOC} form={formPOC} layout="vertical" name="add-poc" style={{ marginTop: 20 }}>
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item

                            rules={[{ required: true, message: 'Please input Name!' }]}
                            name='name' label="Name">
                            <Input placeholder="Enter Name" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <div style={{ width: "100%", display: othersToShow ? "flex" : "block", gap: 8 }}>
                            <Form.Item
                                rules={[{ required: true, message: 'Please input designation!' }]}
                                name="designation" label="Designation">
                                <Select
                                    placeholder="Enter Designation" labelInValue onChange={(value) => {
                                        if (value.label == "Other") {
                                            if (!othersToShow) {
                                                setOthersToShow(true)
                                            }
                                        } else {
                                            setOthersToShow(false)
                                        }
                                    }
                                    }>
                                    {designations?.map((i: any) => {
                                        return <Select.Option value={i.id}>
                                            {i.name}
                                        </Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                            {othersToShow && <Form.Item
                                rules={[{ required: true, message: 'Please input name!' }]}
                                style={{ flex: 1 }} name="designationName" label=" ">
                                <Input placeholder="Enter Designation Name" />
                            </Form.Item>}
                        </div>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[{ required: true, message: 'Please input Contact Number!' },
                            { pattern: /^[0-9]*$/, message: 'Only numbers are allowed!' },
                            { len: 10, message: 'Contact No. must be 10 digits!' }
                            ]}
                            name='contactNumber' label="Contact Number">
                            <Input max={10} placeholder="Enter Contact No." />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[{
                                type: 'email', message: 'Please input valid email!'
                            }]}
                            name='emailId' label="Email">
                            <Input placeholder="Enter Email" />
                        </Form.Item>
                    </Col>

                </Row>

                <div style={{ textAlign: "right", marginTop: 28 }}>
                    <Form.Item>
                        <Button disabled={addingPoc} loading={addingPoc} htmlType="submit" size="large" type="primary">Add</Button>
                    </Form.Item>
                </div>
            </Form>

        </Modal>

        <ShowCertificates setShowCertificates={setShowCertificates} showCertificates={showCertificates} prospectDetails={prospectDetails} handleDownlaod={handleDownlaod} setPreviewFile={setPreviewFile} />

        <ViewDocumentModal setPreviewFile={setPreviewFile} previewFile={previewFile} />

    </div>
}