import React, { Fragment, useEffect, useState } from "react";
import { Tabs, Typography } from "antd";
const TabPane = Tabs.TabPane;
import "../../../styles/Pages.sass";
import InprogressSampleQuoteList from "./components/InprogressSampleQuoteList";
import CompletedSampleQuoteList from "./components/CompletedSampleQuoteList";
import AllSampleQuoteList from "./components/AllSampleQuoteList";
import { useAppSelector } from "../../../app/hooks";
import { menuSelector } from "../../../features/menu/menuSlice";
import NoAccess from "../Prospect/utils/NoAccess";
import { useNavigate, useSearchParams } from "react-router-dom";


function SampleQuoteLayout() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const menuActions = useAppSelector(menuSelector).menuActions;
    const [key, setKey] = useState("");
    const allowedActionsForSampleQuoteList: string[] | undefined = menuActions?.['Sample Quote'];
    const allowedActionsForSampleQuote = new Set(allowedActionsForSampleQuoteList);
    if( !allowedActionsForSampleQuote?.has('sampleQuoteDashboard') )  return <NoAccess/>;

    const handleTabUrl = (tab: string) => {
        setKey(tab.toString());
        navigate(`?tab=${tab}`)
    }
    useEffect(() => {
        setKey(searchParams.get("tab") || "1");
    }, []);

    return (
    <Fragment>
    <div className="parent-container">
        <div className="container-layout">
            <Typography style={{fontSize: 20, fontWeight: 700}}>Sample Quotes</Typography>
            <div className="container-body">
            <Tabs activeKey={key} defaultActiveKey={key} className="tab" onTabClick={(e) => handleTabUrl(e)}>
                <TabPane tab={<span className="tab-heading">All Quotes</span>} key="1">
                    <AllSampleQuoteList allowedActionsForSampleQuote={allowedActionsForSampleQuote}/>
                </TabPane>
                <TabPane tab={<span className="tab-heading">In progress Quotes</span>} key="2">
                    <InprogressSampleQuoteList allowedActionsForSampleQuote={allowedActionsForSampleQuote}/>
                </TabPane>
                <TabPane tab={<span className="tab-heading">Completed Quotes</span>} key="3">
                    <CompletedSampleQuoteList allowedActionsForSampleQuote={allowedActionsForSampleQuote}/>
                </TabPane>
            </Tabs>
            </div>
        </div>
    </div>
    </Fragment>
    );
}

export default SampleQuoteLayout;