import React, { useEffect, useState, useRef } from 'react';
import { getRequest, getRequestForDownload, putRequest } from '../../../../../utils/handler/apiHandler';
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as CrossIcon } from '../.././utils/SVGs/cross_icon.svg';
import { ReactComponent as AddIcon } from '../.././utils/SVGs/add_icon.svg';
import { ReactComponent as DownloadIcon } from '../.././utils/SVGs/Download.svg';
import { ReactComponent as EyeIcon } from '../.././utils/SVGs/eye.svg';
import { ReactComponent as DisablePreviewIcon } from '../.././utils/SVGs/DisablePreviewIcon.svg';
import { ReactComponent as PipeIcon } from '../../../../../utils/icons/Pipe_Icon.svg';
import { ReactComponent as DeleteIcon } from '../../../SVGs/delete_icon.svg';
import { useAppSelector } from '../../../../../app/hooks';
import { userSelector } from '../../../../../features/auth/userSlice';
import { Button, Typography, Tooltip } from 'antd';
import { SALES_ROLE } from '../../../constants';
import FileViewer from './FileViewer';
import { getDisableModifyButtonFlag } from './documentUtils';


interface ViewDocumentsProps {
  showViewDocs: boolean;
  setShowViewDocs: (show: boolean) => void;  
  documentActionTargetId: number;
  setShowUpload: (show: boolean) => void;  
  docUploadStatus: any;
  setLoading: (show: boolean) => void;
  userRole: string;
  setReloadDocumentTab: (reload: number) => void;
  allowedActionsForProspect: any
}

const ViewDocuments: React.FC<ViewDocumentsProps> = ({
  showViewDocs, setShowViewDocs, documentActionTargetId, setShowUpload,
  docUploadStatus, setLoading, userRole, setReloadDocumentTab, allowedActionsForProspect
}) => {
  const [files, setFiles] = useState<Record<string, any[]>>({});
  const [reloadViewDocumentCounter, setReloadViewDocumentCounter] = useState<number>(0);
  const [previewFile, setPreviewFile] = useState<any>(null);
  const [isAnyDocumentUploaded, setIsAnyDocumentUploaded] = useState<boolean>(false);
  const navigate = useNavigate();
  const fileTypeSet = new Set(['docx', 'doc', 'pptx', 'ppt', 'xlsx', 'xls', 'csv']);
  const url = useLocation();
  const cardRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector(userSelector);
  const disableAddNewFilesButton = getDisableModifyButtonFlag(userRole, docUploadStatus?.name);
  const handleDocumentModalCancel = () => {
    setShowViewDocs(false);
    setReloadDocumentTab(Math.random());
  }
  const fetchFiles = async () => {
    try {
      const res = await getRequest(
        `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/prospectDoc/viewFilesNew?prospectDocId=${documentActionTargetId}`
      );

      if (res.status === 404 || res.status === 400) {
        navigate("/dashboard/prospect");
        return;
      }
      // Assuming res.data is in the expected format: { "2024-07-30": [file1, file2], "2024-07-29": [file1, file2] }
      setFiles(res.data);
      if( Object.keys(res.data).length){
        console.log('data=%o',res.data)
        setIsAnyDocumentUploaded(true);
      }
      else{
        setIsAnyDocumentUploaded(false);
      }
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
        handleDocumentModalCancel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    

    fetchFiles();

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [documentActionTargetId, navigate]);

  useEffect(()=>{
    if(reloadViewDocumentCounter!=0){
      console.log(`reloadViewDocumentCounter=%o`,reloadViewDocumentCounter)
      fetchFiles();
    }
  },[reloadViewDocumentCounter]);

  const handlePreview = (file: any) => {
    setPreviewFile(file);
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };

  const handleDownloadAllClick = async () => {
    try {
      const res = await getRequestForDownload(
        `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/prospectDoc/downloadAllNew?prospectDocId=${documentActionTargetId}`,
        'application/zip'
      );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `downloadedDocumentId${documentActionTargetId}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading all documents:', error);
    }
  };

  const handlePreviewCancel = () => {
    setPreviewFile(null);
  };

  const handleFileDownload = async (file: any) => {
    try {
      const response = await fetch(file?.url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blobData = await response.blob();
      const blobUrl = URL.createObjectURL(blobData);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = file?.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleFileDelete = async (file: any) => {
    console.log(`In handleFileDelete file=%o`, file);
    // Implement file deletion logic
    try{
      const response = await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/prospectDoc/delete?id=${file?.id}`,{});
      if(response){
        setReloadViewDocumentCounter((oldVal:number) => oldVal+1);
      }
    }
    catch(err) {
            console.log("Error while deleting file: ", err);
    }
  };

  return (
    <div
      ref={cardRef}
      style={{
        width: previewFile === null ? '36rem' : '66rem',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '60%',
        zIndex: 2,
        borderRadius: '8px 8px 0 0',
        backgroundColor: '#F6FAFF',
        boxShadow: '0px 6px 24px 0px rgba(0, 0, 0, 0.25)'
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: '-0.6rem',
          top: '0.8rem',
          borderRadius: 99,
          width: '100%',
          display: "flex",
          justifyContent: 'flex-end',
          cursor: "pointer"
        }}
        onClick={handleDocumentModalCancel}
      >
        <CrossIcon color='#686868' />
      </div>
      <div style={{ display: 'flex', height: '100%' }}>
        <div
          style={{
            width: previewFile === null ? '100%' : '50%',
            height: '100%',
            padding: '30px 22px',
            borderRight: previewFile === null ? '0px' : '2px rgba(0, 0, 0, 0.20) solid'
          }}
        >
          <div
            style={{
              height: '2%',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: '1.5rem'
            }}
          >
            <Typography className="primary-heading" style={{ color: '#404040' }}>
              Documents
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="primary"
                disabled={!isAnyDocumentUploaded}
                ghost
                onClick={handleDownloadAllClick}
                style={{
                  borderColor: isAnyDocumentUploaded  ? '#1777FF' :  'grey',
                  height: '2.5rem',
                  fontSize: '14px',
                  fontWeight: 400,
                  backgroundColor: 'rgba(23, 119, 255, 0.10)'
                }}
              >
                <span style={{ marginRight: '0.4rem' }}>
                  <DownloadIcon color={isAnyDocumentUploaded  ? '#1777FF' :  'grey' }/>
                </span>
                Download All
              </Button>
              {allowedActionsForProspect.has("addFiles") && <Button
                disabled={disableAddNewFilesButton}
                type="primary"
                ghost
                onClick={() => {
                  setShowViewDocs(false);
                  setShowUpload(true);
                }}
                style={{
                  marginLeft: '1.5rem',
                  borderColor: disableAddNewFilesButton ? 'grey' : '#1777FF',
                  height: '2.5rem',
                  fontSize: '14px',
                  fontWeight: 400,
                  backgroundColor: 'rgba(23, 119, 255, 0.10)'
                }}
              >
                <span style={{ marginRight: '0.4rem' }}>
                  <AddIcon />
                </span>
                Add New Files
              </Button>}
            </div>
          </div>
          <div style={{ height: '95%', margin: '0.5rem 0.1rem', overflowY: 'auto' }}>
            {Object.keys(files).length > 0 ? (
              <div>
                {Object.entries(files).map(([date, filesArray]) => (
                  <div key={date}>
                    <div style={{ color: '#464646', fontSize: '12px', fontWeight: '700', marginBottom: '0.8rem', marginTop: '0.8rem' }}>
                      {formatDate(date)}
                    </div>
                    {filesArray.map((file: any) => {
                      const fileType = file.fileName.split('.').pop();
                      const disablePreview = fileTypeSet.has(fileType || '');

                      return (
                        <div
                          key={file.id}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '0.8rem',
                            padding: '0.5rem 0.6rem',
                            backgroundColor: 'white',
                            borderRadius: '8px'
                          }}
                        >
                          <Tooltip title={file.fileName} style={{ zIndex: 3 }}>
                            <span style={{
                              fontSize: '12px',
                              fontWeight: '500',
                              color: '#404040',
                              maxWidth: '55%',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }}>
                              {file.fileName}
                            </span>
                          </Tooltip>
                          <div style={{ display: 'flex' }}>
                            {file.allowDelete && user?.ssoId === JSON.parse(file?.createdBy)?.ssoId && (
                              <div style={{ display: 'flex' }}>
                                {allowedActionsForProspect.has("addFiles") && <Button
                                  style={{ color: '#FF4D4F', backgroundColor: 'white', border: 'none', boxShadow: 'none', fontWeight: '500' }}
                                  onClick={() => handleFileDelete(file)}
                                >
                                  <div style={{ display: 'flex' }}>
                                    <DeleteIcon color='#FF4D4F' style={{ marginRight: '5px', marginTop: '3px' }} />
                                    <p>Delete</p>
                                  </div>
                                </Button>}

                                {(allowedActionsForProspect.has("addFiles") && allowedActionsForProspect.has("viewDocs")) && <div style={{ marginTop: '7px' }}>
                                  <PipeIcon />
                                </div>}
                              </div>
                            )}
                            <Button
                              style={{ color: '#2C66E3', backgroundColor: 'white', border: 'none', boxShadow: 'none', fontWeight: '500' }}
                              onClick={() => handleFileDownload(file)}
                            >
                              <div style={{ display: 'flex' }}>
                                <DownloadIcon color='#2C66E3' style={{ marginRight: '5px', marginTop: '5px' }} />
                                <p>Download</p>
                              </div>
                            </Button>
                            <div style={{ marginTop: '7px' }}>
                              <PipeIcon />
                            </div>
                            {previewFile === file ? (
                              <Button
                                icon={<DisablePreviewIcon color='#2C66E3' />}
                                style={{ color: '#2C66E3', backgroundColor: 'white', border: 'none', boxShadow: 'none', fontWeight: '500' }}
                                onClick={handlePreviewCancel}
                              >
                                {'Hide Preview'}
                              </Button>
                            ) : disablePreview ? (
                              <div style={{ marginTop: '6px', marginLeft: '12px' }}>
                                <Tooltip title='Preview not supported for this file format' style={{ zIndex: 3 }}>
                                  <DisablePreviewIcon color='grey' />
                                </Tooltip>
                              </div>
                            ) : (
                              <Button
                                disabled={disablePreview}
                                style={{ color: disablePreview ? 'grey' : '#2C66E3', backgroundColor: 'white', border: 'none', boxShadow: 'none', fontWeight: '500' }}
                                onClick={() => handlePreview(file)}
                              >
                                <div style={{ display: 'flex' }}>
                                  <EyeIcon color='#2C66E3' style={{ marginRight: '5px', marginTop: '4px' }} />
                                  <p>Preview</p>
                                </div>
                              </Button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            ) : (
              <div>No files available</div>
            )}
          </div>
        </div>
        {previewFile && (
          <div style={{ display: 'flex', width: '50%', height: '100%', padding: '10px' }}>
            <FileViewer previewFile={previewFile} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewDocuments;
