import React, { Fragment, useEffect, useState } from "react";
import { Tabs, Typography } from "antd";
const TabPane = Tabs.TabPane;
import "../../../styles/Pages.sass";
import AllCreditAssessmentRequest from "./Assessment/AllCreditAssessmentRequest";
import InProgressCreditAssessmentRequest from "./Assessment/InProgressCreditAssessmentRequest";
import ApprovedCreditAssessmentRequest from "./Assessment/ApprovedCreditAssessmentRequest";

import { menuSelector } from "../../../features/menu/menuSlice";
import { useAppSelector } from "../../../app/hooks";
import NoAccess from "../Prospect/utils/NoAccess";
import { useNavigate, useSearchParams } from "react-router-dom";

function CreditAssessmentRequest() {
    const navigate = useNavigate();
    const [key, setKey] = useState("");
    const [searchParams] = useSearchParams();
    const menuActions = useAppSelector(menuSelector).menuActions;
    const allowedActionsForCreditAssessmentList: string[] | undefined = menuActions?.['Credit Assessment'];
    const allowedActionsForCreditAssessment = new Set(allowedActionsForCreditAssessmentList);
    if( !allowedActionsForCreditAssessment?.has('creditDashboard') )  return <NoAccess/>;
    const handleTabUrl = (tab: string) => {
        setKey(tab.toString());
        navigate(`?tab=${tab}`)
    }
    useEffect(() => {
        setKey(searchParams.get("tab") || "1");
    }, []);
    return (
    <Fragment>
    <div className="parent-container">
        <div className="container-layout">
            <Typography style={{fontSize: 20, fontWeight: 700}}>Credit Assessment Requests</Typography>
            <div className="container-body">
            <Tabs activeKey={key} defaultActiveKey={key} className="tab" onTabClick={(e) => handleTabUrl(e)}>
                <TabPane tab={<span className="tab-heading">All Assessments</span>} key="1">
                    <AllCreditAssessmentRequest />
                </TabPane>
                <TabPane tab={<span className="tab-heading">In progress</span>} key="2">
                    <InProgressCreditAssessmentRequest />
                </TabPane>
                {
                    allowedActionsForCreditAssessment?.has('approvedCreditRequest') &&
                    <TabPane tab={<span className="tab-heading">Approved Assessments</span>} key="3">
                        <ApprovedCreditAssessmentRequest />
                    </TabPane>
                }
                
            </Tabs>
            </div>
        </div>
    </div>
    </Fragment>
    );
}

export default CreditAssessmentRequest;