import React from 'react';
import DocViewer from '@cyntler/react-doc-viewer';

interface FilePreviewProps {
  previewFile: any;
}
    
const FilePreview: React.FC<FilePreviewProps> = ({ previewFile }) => {
    return previewFile ? (
        <div style={{ width: '100%', minHeight: '150px', overflow: "scroll", backgroundColor: 'white', margin: '1rem 1rem' }}>
            <DocViewer
                key={previewFile ? previewFile.name : 'none'} // Change key when previewFile changes
                documents={previewFile ? [{ uri: URL.createObjectURL(previewFile.originFileObj), fileType: previewFile.type, fileName: previewFile.name }] : []} // Ensure documents array is empty if previewFile is null
            />
        </div>
    ) : null;
}

export default FilePreview;
