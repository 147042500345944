import qs from "qs";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

export const changeQueryParam = (key: string, value: any, searchParams: any) => {
  const newSearchParams = new URLSearchParams(searchParams);
  newSearchParams.set(key, value);

  const urlQueryObj = qs.parse(newSearchParams.toString());
  let o = Object.keys(urlQueryObj).forEach((k) => (urlQueryObj[k] === 'null' || urlQueryObj[k] === '') && delete urlQueryObj[k]);
  
  history.push(`?${newSearchParams}`);
};


export const addOrUpdateParam = (key: any, value: any, searchParams: any, setSearchParams: any) => {
  if (value) {
    searchParams.set(key, value); // Add or update the param
  } else {
    searchParams.delete(key); // Remove the param if value is empty
  }
  setSearchParams(searchParams); // Update the URL
};

export const removeParam = (key: any, searchParams: any, setSearchParams: any) => {
  searchParams.delete(key);
  setSearchParams(searchParams);
};